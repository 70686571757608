<template>
    <section class="advantages">
        <base-title margin="mb-small">مميزاتنا</base-title>
        <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-11 col-sm-11 mx-auto" v-for="(adv, index) in advantages" :key="adv.id"
                    data-aos="zoom-in" data-aos-easing="ease" :data-aos-delay="800 + index * 100">
                    <div class="cart" :class="'cart'+index">
                        <div class="face face1 d-flex align-items-center justify-content-center">
                            <div class="content">
                                <base-heading>{{ adv.title }}</base-heading>
                                <h2>
                                    {{ adv.description }}
                                </h2>
                            </div>
                        </div>
                        <div class="face face2 d-flex align-items-center justify-content-center">
                            <div class="content">
                                <p class="mb-0">
                                    {{ adv.details }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            advantages: [
                {
                    id: 1,
                    title: 'الدقة',
                    description: 'الإلتزام التام في أداء المشاريع وفقا للمعايير الموضوعة لها ',
                    details: 'الدقة مهمة جدًا للتأكد من تلبية طلبات العملاء دائمًا بالمعايير الدقيقة التي يتوقعونها منا. نحن نضمن أن المنتج الذي نقدمه لك هو شيء نحن متأكدون 100٪ أنه ما تحتاجه. مع مطور لن تقلق أبدًا من حصولك على شيء لم تكن تريده',
                },
                {
                    id: 2,
                    title: 'الشفافية',
                    description: 'الثقة بين الشركة ومستثمريها وعملائها وشركائها وموظفيها.',
                    details: 'لثقة من أهم الصفات التي يجب أن تكون بين الشركة وعملائها. سنبذل دائمًا قصارى جهدنا لتلبية ما تحتاجه ونهدف إلى القيام بذلك من خلال الشفافية التامة في جميع الأوقات. يمكنك دائمًا في أي وقت التحقق من تقدمنا وتقديم ملاحظاتك حتى نتمكن من معرفة ما إذا كنا على  نفس الصفحة لتحقيق أهدافنا وأهدافك',
                },
                {
                    id: 3,
                    title: 'الاحترافية',
                    description: 'المهارات التي نمتلكها ستجعلك الأفضل في السوق.',
                    details: ' لدينا الفريق ومجموعة المهارات التي تحتاجها لتحقيق أهدافك. هذا الفريق مليء بأصحاب الخبرة والمهرة مدربين تدريباً عالياً ويقدمون دائمًا أفضل النتائج. لدينا عملية مبسطة وسلسة تضمن عدم حدوث أي خطأ. يمكنك أن تكون متأكدا أن المهارات التي نمتلكها ستجعلك الأفضل في السوق.',
                }
            ]
        }
    },
}
</script>

<style scoped>
.advantages {
    padding-bottom: 60px;
}

.advantages .cart .face {
    width: 100%;
    height: 200px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    cursor: pointer;
}

.advantages .cart .face.face1 {
    position: relative;
    background: #fff;
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
    z-index: 1;
}



.advantages .cart .face.face2 {
    position: relative;
    background: #fff;
    padding: 20px;
    box-sizing: border-box;
    -webkit-box-shadow: 0 16px 16px rgba(0, 0, 0, 0.14);
    box-shadow: 0 16px 16px rgba(0, 0, 0, 0.14);
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
    min-height: 200px;
}

.advantages .cart:hover .face.face1 {
    background: #fff;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.advantages .cart:hover .face.face2 {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.advantages .cart h2 {
    font-size: 25px;
    font-weight: bold;
    z-index: 2;
    color: var(--header-color);
    position: relative;
    margin-top: 30px;

}

.advantages .cart h2::before {
    content: "0" counter(advantages-counter);
    counter-increment: advantages-counter;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: Merriweather, serif;
    font-size: 100px;
    color: #f4f4f2;
    z-index: -1;
}

.advantages .cart p {
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    line-height: 27px;
}

@media (max-width:992px) {
    .advantages .cart0 .face1 {
        background: #fff !important;
        -webkit-transform: translateY(0) !important;
        transform: translateY(0) !important;
    }

    .advantages .cart0 .face2 {
        -webkit-transform: translateY(0) !important;
        transform: translateY(0) !important;
        opacity: 1 !important;
        margin-bottom: 20px;

    }
}
</style>