<template>
    <section class="contact-us d-flex justify-content-center align-items-center" id="contactUs">
        <div class="box text-center d-flex flex-column align-items-center justify-content-center px-2">
            <h1 data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                كيف يمكن أن نساعد؟
            </h1>
            <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                نحن نتفهم أن تعزيز رضا المستخدم عن المنتج مهم جدًا. إنه عامل أساسي سيؤثر على مبيعاتك.
            </p>
            <button class="large-btn open-form-btn" @click="showDialog" data-aos="fade-up" data-aos-easing="ease"
                data-aos-delay="800">
                اتصل بنا
                <font-awesome-icon icon="fa-solid fa-arrow-left-long" />
            </button>
        </div>
    </section>
    <keep-alive>
        <contact-modal @close="hideDialog" :open="dialogIsVisible"></contact-modal>
    </keep-alive>
</template>

<script>
import ContactModal from '../contact/ContactModal.vue';
export default {
    components: {
        ContactModal
    },
    data() {
        return {
            dialogIsVisible: false
        }
    },
    methods: {
        showDialog() {
            this.dialogIsVisible = true
        },
        hideDialog() {
            this.dialogIsVisible = false
        }

    },
}
</script>
<style scoped>
.contact-us {
    height: 700px;
    background-color: var(--primary-color);
}

.contact-us .box {
    background-color: #fff;
    width: 90%;
    height: 80%;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.contact-us .box h1 {
    font-size: 50px;
    line-height: 1.4em;
    font-weight: 800;
    color: var(--header-color);
    margin-bottom: 26px;
}
@media (max-width:540px) {
    .contact-us .box h1{
        font-size: 38px;
    }
}
.contact-us .box p {
    color: #696969;
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
    margin-bottom: 40px;
}

.open-form-btn {
    color: #000 !important;
    font-weight: 700 !important;
    border: 1px solid #e1e1e1 !important;
    background: -webkit-gradient(linear, right top, left top, color-stop(50%, #fac400), color-stop(50%, white)) !important;
    background: linear-gradient(to left, #fac400 50%, white 50%) !important;
    background-size: 200% 100% !important;
    background-position: left bottom !important;
    -webkit-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
}

.open-form-btn:hover {
    background-position: right bottom !important;
    color: white !important;
}

.open-form-btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1x solid #000 !important;
}

.open-form-btn svg {
    margin-right: 20px;
}
</style>