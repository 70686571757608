<template>
    <div class="text">

        <h3 class="fw-bold my-3" data-aos="fade-up">
            ما هو الواقع المعزز
        </h3>
        <p data-aos="fade-up">
            إلى قاموس عالم التكنولوجيا (AR) لقد مر ما يقرب من ثلاثة عقود منذ أن تمت إضافة مصطلح الواقع
            المعزز
            كأداة داخلية للمساعدة في التصميم ، لم يتخيل أحد أن الواقع المعزز ستصبح تقنية شائعة.
            في الواقع ، لم يكن أحد في أحلامه يتخيل أن تصبح تطبيقات الواقع المعزز متاحة بسهولة.
            لكننا نعلم مدى السرعة التي أصبح بها الواقع المعزز جزءًا لا يتجزأ من الحياة اليومية. أصبحت
            تجربة الواقع الغامر التي يقدمها الــواقع المعزز شائعة جدًا لدرجة أننا لم نعد نجدها تقنية
            غريبة بعد الآن.
            وهذه بالتأكيد أخبار جيدة للشركات. خاصة عندما يرغبون في إشراك العملاء فعليًا ولا يمكنهم تحمل
            تكلفة التفاعل معهم شخصيًا

        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            ما الفرق بين الواقع المعزز والواقع الافتراضي؟
        </h3>
        <p data-aos="fade-up">
            يعتقد الكثير من الناس خطأً أن الواقع المعزز والواقع الافتراضي متماثلان. بالتأكيد ، إنهما
            متشابهان ، لكنهما مختلفان تمامًا أيضًا عن بعضهما البعض. يخلق الواقع الافتراضي واقعًا جديدًا
            تمامًا باستخدام تكنولوجيا الكمبيوتر. يجعلك تعتقد أنك في عالم مختلف من خلال إغلاق العالم
            الحقيقي.
            الواقع المعزز ، من ناحية أخرى ، يعزز العالم الذي أنت فيه. تظل في نفس العالم ، باستثناء أنك
            ترى كائنات إضافية معروضة يمكنك حتى التفاعل معها.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            سرعة اتصال الخادم بالانترنت
        </h3>
        <p data-aos="fade-up">
            تؤثر سرعة اتصال الخادم بالانترنت على سرعة نقل البيانات بين المستخدم والاستضافة والعكس مما
            يؤثر بدوره على سرعة موقع الويب بصورة مباشرة ويظهر ذلك بصورة واضحة جدًا إذا كانت سرعة اتصال
            المستخدم أعلى من سرعة اتصال السيرفر وفي أوقات زيادة عدد زوار موقعك أيضًا.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            ما هي استخدامات الواقع المعزز؟
        </h3>
        <p data-aos="fade-up">
            .AR لمدى قيمة نجاحًا هائلاً لدرجة أنه أصبح في الأساس دليلًا على مفهوم PokemonGo الألعاب
            والترفيه: حقق
            نتوقع ظهور المزيد من ألعاب الواقع المعزز في عالم الأجهزة المحمولة.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            السفر والسياحة:
        </h3>
        <p data-aos="fade-up">
            يمكن للسياحة استخدام يد من الواقع المعزز. تخيل أنك تتجول في المدينة في بلد أجنبي ، وعندما
            ترى معلمًا مثيرًا للاهتمام ، كل ما عليك فعله هو توجيه كاميرا هاتفك إليه ، وستعرف على الفور
            المزيد عنه.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            التعليم:
        </h3>
        <p data-aos="fade-up">
            الواقع المعزز هو عامل تغيير محتمل للعبة في التعليم. فقط تخيل فصل دراسي محسّن بالواقع المعزز.
            تعمل المجالات الطبية والرعاية الصحية أيضًا على تطوير تطبيقات تدريبية تغطي مجموعة واسعة من
            مجموعات المهارات ، من تشغيل المعدات الطبية المتطورة إلى إجراء الجراحة بمساعدة
        </p>

        <h5 class="fw-bold mt-5" data-aos="fade-up">
            <i class="fas fa-pencil-alt me-2"></i>
            أبرز اقوال
            <span class="author">"مارك زوكربيرج"</span>
        </h5>

        <blockquote data-aos="fade-down">
            الواقع الافتراضي يُمكن أن يضعك في أي مكان، والواقع المُعزز يُمكن أن يجلب إليك أي شيء؛ إن أي
            استثمار في الواقع الافتراضي هو إلى حد ما استثمار في الواقع المُعزز. كلاهما سيكون مهيمنا
        </blockquote>
    </div>
</template>