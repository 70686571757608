import socialMediaGetters from './getters.js';

export default {
    state() {
        return {
            socialMedia: [
                {
                    id: "fb",
                    link: "https://www.facebook.com/mootawer",
                    icon: "fa-brands fa-facebook-f",
                },
                {
                    id: "insta",
                    link: "https://www.instagram.com/mootawer",
                    icon: "fa-brands fa-instagram",
                },
                {
                    id: "linkedn",
                    link: "https://www.linkedin.com/company/mootawer-ltd",
                    icon: "fa-brands fa-linkedin",
                },
            ],
        }
    },
    getters: socialMediaGetters
}