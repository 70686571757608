
import portfolioGetters from './getters.js';

export default {
    state() {
        return {
            portfolios: [
                {
                    id: 'galleria',
                    cover: "/images/galleria/1.webp",
                    title: "فكرة التطبيق",
                    description:
                        "تطبيق يدعم الواقع المعزز (AR) يهدف لتحقيق مشاريع المستخدمين في بريطانيا",
                    name: 'جالوريا',
                    details: 'يقدم التطبيق إمكانيات خاصة بالتجار لعرض المنتجات بطرق متقدمة - حيث يمكن عرض جاليري، تأثيرات الحركة، فيديو، تفاصيل المنتج. تعرض المنتجات بطرق متنوعة - عرض خيارات المنتجات (الألوان، الحجم، المفاس) بالإضافة لخيارات أخرى. شراء المنتجات و إتمام عمليات الدفع بوجود كل عناصر الأمان بوسائل دفع متعددة. بنرات إعلانية، أقسام لا نهائية، لوحة تحكم شاملة و الكثير منأحدث الخصائص على مستوى العالم. يعمل بنظام الأندرويد Android و آي أو إس iOS',
                    type: 'تطبيق إلكتروني',
                    date: 'آذار، 2022',
                    trade: false,
                    images: [
                        '/images/galleria/2.webp',
                        '/images/galleria/3.webp',
                        '/images/galleria/4.webp',
                        '/images/galleria/5.webp',
                        '/images/galleria/6.webp',
                        '/images/galleria/7.webp',
                        '/images/galleria/8.webp',
                        '/images/galleria/9.webp',
                    ],

                },
                {

                    id: 'taqdoom',
                    cover: "/images/taqdoom/1.webp",
                    title: "فكرة التطبيق",
                    description: "تطبيق متعدد المطاعم في سلطنة عمان لعرض المنتجات ومتابعه الاوردرات والدفع الاونلاين",
                    name: 'تقدوم',
                    details: ' تطبيق الكتروني صمم خصيصا لخدمة طلبات الزبائن بواسطة الهاتف أو التاب أو أي جهاز يعمل بنظام الأندرويد Android و آي أو إس iOS',
                    specifications: [
                        'الفئات الفرعية واألأطعمة المختارة',
                        'البحث عن الطعام والتصفية حسب النوع او السعر وتفاصيل الطعام',
                        'إضافة إلى عربة التسوق وإدارة السلة و معاملة الشراء',
                        'منتجات عليها خصم وقسائم الخصم و مجموعة األطعمة و المنتج المفضل للمستخدم',
                        'تسجيل دخول المستخدم وتسجيله وتسجيل الخروج منه',
                        'المنتجات الأكثر شيوعاً والمنتجات الكثر شراءاً والفئات الكثر شيوعاً'
                    ],
                    type: 'تطبيق إلكتروني',
                    date: 'أيار، 2022',
                    trade: true,
                    images: [
                        '/images/taqdoom/2.webp',
                        '/images/taqdoom/3.webp',
                        '/images/taqdoom/4.webp',
                        '/images/taqdoom/5.webp',
                        '/images/taqdoom/6.webp',
                        '/images/taqdoom/7.webp',
                        '/images/taqdoom/8.webp',
                        '/images/taqdoom/9.webp',
                    ],

                },
                {
                    id: 'aswaqway',
                    cover: "/images/aswaqway/1-cover.webp",
                    title: "فكرة التطبيق",
                    description: "  يلبي التطبيق جميع وظائف التجارة الإلكترونية  تطبيق متعدد التجار في لبنان (ملتي فيندور/Multi Vendor)",
                    name: 'أسواق وآي',
                    details: 'يقدم التطبيق إمكانيات خاصة بالتجار لعرض المنتجات بطرق متقدمة - حيث يمكن عرض جاليري، تأثيرات الحركة، فيديو، تفاصيل المنتج. تعرض المنتجات بطرق متنوعة - عرض خيارات المنتجات (الألوان، الحجم، المفاس) بالإضافة لخيارات أخرى. شراء المنتجات و إتمام عمليات الدفع بوجود كل عناصر الأمان بوسائل دفع متعددة. بنرات إعلانية، أقسام لا نهائية، لوحة تحكم شاملة و الكثير منأحدث الخصائص على مستوى العالم. يعمل بنظام الأندرويد Android و آي أو إس iOS',
                    type: 'تطبيق إلكتروني',
                    date: 'آذار، 2022',
                    trade: false,
                    images: [
                        '/images/aswaqway/2.webp',
                        '/images/aswaqway/3.webp',
                        '/images/aswaqway/4.webp',
                        '/images/aswaqway/5.webp',
                        '/images/aswaqway/6.webp',
                        '/images/aswaqway/7.webp',
                        '/images/aswaqway/8.webp',
                        '/images/aswaqway/9.webp',
                    ],
                },
                {
                    id: 'mustawdaat',
                    cover: "/images/mustawdaat/1-cover.webp",
                    title: "فكرة التطبيق",
                    description:"تطبيق يقدم ميزة المزايدات على المستودعات أو إستئجارها في المملكة العربية السعودية",
                    name: 'مستودعات',
                    details: 'يتضمن التطبيق عرض المنتجات والأدوات و الأجهزة المتنوعة إستئجار المستودعات كامل/جزئي و أيضا بيع و شراء المستودعات من خلال نظام كامل للمزايدات يحتوي التطبيق على نظام يدعم نقاط الولاء و كوبونات الخصم و يوفر وسائل شحن و دفع متعددة أقسام لا نهائية، منتجات أكثر شيوعاً و الأكثر شراءاً و الفئات الأكثر شيوعاً يعمل بنظام الأندرويد Android و آي أو إس iOS',
                    type: 'تطبيق إلكتروني',
                    date: 'آب، 2022',
                    trade: false,
                    images: [
                        '/images/mustawdaat/2.webp',
                        '/images/mustawdaat/3.webp',
                        '/images/mustawdaat/4.webp',
                        '/images/mustawdaat/5.webp',
                        '/images/mustawdaat/6.webp',
                        '/images/mustawdaat/7.webp',
                        '/images/mustawdaat/8.webp',
                        '/images/mustawdaat/9.webp',
                    ],
                },
                {
                    id: 'taknikat',
                    cover: "/images/taknikat/1-cover.webp",
                    title: "فكرة التطبيق و الموقع",
                    description:"تطبيق للمصورين المحترفين و عشاق التصوير والطباعة في المملكة العربية السعودية",
                    name: 'تكنيكات',
                    details: 'تم انشاء الموقع و التطبيق ليقوم بتسهيل طلب وبحث عن موفر الخدمة بشكل سريع والاستفادة منها بأنسب الأسعار، بالإضافة الى خدمات التدريس والتطوير في اغلب مجالات الفن الجميل والمشاركة بالفعاليات مؤسسة تكنيكات هي مؤسسة الكترونية تعمل في مجال الفن الجميل بمغلب مجالاته هدفها الارتقاء بمستوى الابداع الفني عبر إيجاد الخامات الفنية في الدول العربية خصيصا، والعالمية عامة تسعى لإبراز المبدعين في مجال الفن بمختلف أنواعه الى العالم بشكل سلس ومميز والتعريف بالشخصيات الفنية عبر منصة تفاعلية  تساهم في تقديم خدمات مختصة في مجال الفنون عبر تسهيل التعامل بين الفنان وطالب والخدمة بالإضافة الى منصة مبيعات الأدوات والمعدات التي تخدم هذا المجال. يعمل التطبيق بنظام الأندرويد Android و آي أو إس iOS',
                    type: 'تطبيق إلكتروني',
                    date: 'كانون الثاني، 2022',
                    trade: false,
                    images: [
                        '/images/taknikat/2.webp',
                        '/images/taknikat/3.webp',
                        '/images/taknikat/4.webp',
                        '/images/taknikat/5.webp',
                        '/images/taknikat/6.webp',
                        '/images/taknikat/7.webp',
                        '/images/taknikat/8.webp',
                        '/images/taknikat/9.webp',
                    ],
                },
            ],
        }
    },
    getters: portfolioGetters
}