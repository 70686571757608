<template>
  <section class="pb-5">
    <base-banner mode="yellow">المدونة</base-banner>
    <div class="container">
      <!-- Start Row 1 -->
      <div class="row">
        <!-- Start blog -->
        <blog-cover
          v-for="blog in showBlogs"
          :key="blog.id"
          :id="blog.id"
          :title="blog.title"
          :question="blog.question"
          :image="blog.imageSM"
          :dates="blog.dates"
        >
        </blog-cover>
        <!-- End blog -->
      </div>
    </div>
  </section>
</template>

<script>
import BlogCover from "../components/blogs/BlogCover.vue";
export default {
  components: { BlogCover },
  computed: {
    showBlogs() {
      return this.$store.getters["blogs"];
    },
  },
};
</script>
