<template>
  <section class="single-blog pb-5">
    <base-banner mode="yellow">المدونة</base-banner>
    <div class="container">
      <div class="row">
        <div class="col-xl-12 m-auto">
          <div class="blog-image position-relative" data-aos="zoom-in-up">
            <img :srcset="image" :alt="selectedBlog.id" class="w-100 h-100" />
            <div class="date d-flex align-items-center justify-content-center">
              <span v-for="date in dates" :key="date">
                {{ date.month }}
                <br />
                {{ date.day }}
              </span>
            </div>
          </div>
          <div class="text-box">
            <base-heading animate>{{ selectedBlog.title }}</base-heading>

            <!-- large Screen Share Button -->
            <div class="share-btn">
              <base-share-large></base-share-large>
            </div>
            <!-- End large Screen Share Button  -->

            <h2>{{ selectedBlog.question }}</h2>
            <!-- Start Static Text  -->
            <text-one v-if="id == 1"></text-one>
            <text-two v-if="id == 2"></text-two>
            <text-three v-if="id == 3"></text-three>
            <text-four v-if="id == 4"></text-four>
            <text-five v-if="id == 5"></text-five>
            <!-- Start End Text  -->
          </div>
          <!-- Start Others Bolgs -->
          <h2 data-aos="fade-up">المنشورات ذات الصلة</h2>
          <div class="row blogs small-projects">
            <blog-cover v-for="blog in customBlogs" :key="blog.id" :id="blog.id" :title="blog.title"
              :question="blog.question" :image="blog.imageSM" :dates="blog.dates"></blog-cover>
          </div>
          <!-- End Other blog -->
        </div>

        <!-- Start Left Side -->
        <!-- <left-blog></left-blog> -->
        <!-- End Left -->
      </div>
    </div>
  </section>
</template>

<script>
// Import Blog Text Css
import "../assets/css/blog-text.css";
// Import Blog Text Content
import TextOne from "@/components/blogs/textBox/TextOne.vue";
import TextTwo from "@/components/blogs/textBox/TextTwo.vue";
import TextThree from "@/components/blogs/textBox/TextThree.vue";
import TextFour from "@/components/blogs/textBox/TextFour.vue";
import TextFive from "@/components/blogs/textBox/TextFive.vue"
// Import Blog Cover
import BlogCover from "@/components/blogs/BlogCover.vue";
export default {
  components: {
    TextOne,
    TextTwo,
    TextThree,
    TextFour,
    TextFive,
    BlogCover,
  },
  props: ["id"],
  data() {
    return {
      selectedBlog: {},
    };
  },
  created() {
    // Get Selected Blog ID
    this.selectedBlog = this.$store.getters.getBlog(this.id);
  },
  updated() {
    // Update The Selected Blog
    this.selectedBlog = this.$store.getters.getBlog(this.id);
  },
  computed: {
    image() {
      return this.selectedBlog.imageLG;
    },
    dates() {
      return this.selectedBlog.dates;
    },
    customBlogs() {
      const customBlogs = this.$store.getters["blogs"];
      return customBlogs.filter((blog) => blog.id != this.id).slice(0, 3);
    },
  },
};
</script>

<style scoped>
/* Start single-blog */
.single-blog {
  position: relative;
}

.share-btn {
  position: absolute;
  left: 55px;
  top: 85px;
  cursor: pointer;
}

.single-blog .blog-image {
  width: 100%;
  height: 475px;
  overflow: hidden;
}

.single-blog .blog-image img {
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.single-blog .blog-image img:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.single-blog .blog-image .date {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 76px;
  height: 76px;
  background-color: var(--primary-color);
  color: #fff;
  text-align: center;
  font-size: 21px;
}

.single-blog .text-box {
  padding: 40px;
  background-color: #fafafa;
  margin-bottom: 50px;
  position: relative;
}

.single-blog h2 {
  font-size: 25px;
  line-height: 1.2em;
  font-weight: 800;
  margin: 10px 0;
  color: var(--header-color);
}

.single-blog .small-projects {
  margin: 50px 0 20px;
  padding: 0 !important;
}

.single-blog .small-projects .card {
  width: 100%;
  min-height: 430px;
}

.single-blog .small-projects .card .card-text {
  font-size: 17px;
  height: 50px;
  overflow: hidden;
}

.single-blog .small-projects .card .date {
  width: 50px;
  height: 50px;
  font-size: 14px;
}

@media (min-width: 1400px) {
  .single-blog .small-projects .card {
    width: 280px;
  }
}

@media (max-width: 992px) {
  .single-blog blockquote {
    font-size: 17px !important;
    width: 100% !important;
  }
}

@media (max-width: 540px) {
  .single-blog .blog-image {
    height: 300px;
  }

  .single-blog .text-box {
    padding: 20px;
  }

  .single-blog .text-box h2 {
    font-size: 21px;
  }

  .share-btn {
    top: unset;
    left: 55px;
    bottom: 50px;
  }

  .left-bar h3 {
    text-align: center;
  }
}

@media (max-width: 300px) {
  .single-blog .blog-image {
    height: 270px;
  }
}

/* End single-blog */
</style>
