<template>
    <div class="col-xl-4 col-lg-6 col-md-6">
        <router-link :to="'/blog/' + id" class="w-100 h-100">
            <div class="card">
                <div class="card-img-top position-relative" data-aos="zoom-in" data-aos-easing="ease"
                    data-aos-delay="400">
                    <img :srcset="image" class="w-100 h-100" :alt="title">
                    <div class="date d-flex align-items-center justify-content-center">
                        <span v-for="date in dates" :key="date">
                            {{ date.month }}
                            <br />
                            {{ date.day }}
                        </span>
                    </div>
                </div>
                <div class="card-body">
                    <base-heading animate>{{ title }}</base-heading>
                    <p class="card-text" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                        {{ question }}
                    </p>
                   <base-arrow :to="'/blog/' + id">اقرأ المزيد</base-arrow>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: ['id', 'title', 'question', 'image', 'dates']
}
</script>
<style scoped>
.card {
    border: none;
    width: 340px;
    margin: 0 auto 50px;
}

.card .card-img-top .date {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 76px;
    height: 76px;
    background-color: var(--primary-color);
    color: #fff;
    text-align: center;
    font-size: 21px;
}

.card .card-body {
    padding: 45px 27px 51px;
    background-color: #fafafa;
}



.card .card-text {
    color: var(--header-color);
    font-size: 25px;
    font-weight: bold;
    line-height: 1.5em;
    margin-top: 20px;
    margin-bottom: 30px;
}

.card-img-top {
    overflow: hidden;
    cursor: pointer;
    height: 240px !important;
}

.card-img-top img {
    -webkit-transition: 0.7s ease;
    transition: 0.7s ease;
}

.card-img-top img:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
@media (max-width: 768px) {
    .card {
        width: 300px;
    }
    .card-text {
        font-size: 21px;
    }   
}

@media (max-width: 280px) {
    .card {
        width: 260px;
    }
}
</style>