<template>
    <section class="blogs">
        <div class="container">
            <!-- Start Row 1 -->
            <div class="row">
                <!-- Start blog -->
                <blog-cover v-for="(blog) in showBlogs" :key="blog.id" :id="blog.id" :title="blog.title"
                    :question="blog.question" :image="blog.imageSM" :dates="blog.dates"></blog-cover>
                <!-- End blog -->
            </div>
            <!-- start view more Button -->
            <base-more-button to="/blogs"></base-more-button>
            <!-- End view more Button -->
        </div>
    </section>
</template>
  
  <script>
import BlogCover from '../blogs/BlogCover.vue';
export default {
    components: { BlogCover },
    computed: {
        showBlogs() {
            const homeBlogs = this.$store.getters["blogs"];
            return homeBlogs.slice(0, 3);
        },
    },
};
</script>
  
  <style scoped>
  .blogs{
    padding: 100px 0 60px;
  }
  </style>