// Import Vue Route
import { createRouter, createWebHistory } from "vue-router";
// Import Pages
import HomePage from "./pages/HomePage.vue";
import BlogsPage from "./pages/BlogsPage.vue";
import SingleBlog from "@/pages/SingleBlog";
import PortfolioPage from "./pages/PortfolioPage.vue";
import SinglePortfolio from "./pages/SinglePortfolio.vue";
import ContactSuccessPage from "./pages/ContactSuccessPage.vue";
import NotFound from "./pages/NotFound.vue";

// Create Route
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, _, savedPosition) {
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },

  routes: [
    {
      path: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      component: HomePage,
      meta: {
        title: "Mootawer",
      },
    },
    {
      path: "/blogs",
      component: BlogsPage,
      meta: {
        title: "Mootawer | Blogs",
      },
    },
    {
      path: "/portfolios",
      component: PortfolioPage,
      meta: {
        title: "Mootawer | Portfolio",
      },
    },
    {
      path: "/portfolio/:id",
      props: true,
      component: SinglePortfolio,
      meta: {
        title: "Mootawer | Portfolio",
      },
    },
    {
      path: "/blog/:id",
      props: true,
      component: SingleBlog,
      meta: {
        title: "Mootawer | Blogs",
      },
    },
    {
      path: "/contact-success",
      name:'contact-success',
      props: true,
      component: ContactSuccessPage,
      meta: {
        title: "Mootawer | Contact",
        gtm: 'contact-success'
      },
    },
    { path: "/:notFound(.*)", component: NotFound },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "Mootawer";
  next();
});

// To Use in main.js
export default router;
