<template>
  <div class="banner d-flex align-items-center" :class="mode">
    <h2 class="mx-auto me-lg-auto ms-lg-5">
      <slot></slot>
    </h2>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: true,
      default: null,
    },
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 199px;
  margin-top: 100px;
  margin-bottom: 60px;
}

h2 {
  font-size: 50px;
  line-height: 1.1em;
  font-weight: 800;
  margin-right: 60px;
}

.grey {
  background: url(../../../public/images/banners/grey.jpg);
}
.grey h2 {
  color: #000;
}
.yellow {
  background: url(../../../public/images/banners/yellow.jpg);
}
.yellow h2 {
  color: #fff;
}
</style>
