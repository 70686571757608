<template>
  <section class="experts">
    <div class="container">
      <div class="row align-items-center flex-column-reverse flex-xl-row">
        <!-- Experts Descrition -->
        <div class="col-xl-6 col-lg-10 mx-lg-auto">
          <div class="box">
            <base-heading animate>{{ title }}</base-heading>
            <div class="text-box" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
              <h1>
                {{ subTitle }}
              </h1>
              <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
                {{ description }}
              </p>
            </div>
          </div>
        </div>
        <!-- End Descrition -->

        <!-- Start Images Carousel  -->
        <div class="col-xl-6 col-lg-10 mx-lg-auto mb-3" data-aos="fade-in" data-aos-easing="ease" data-aos-delay="400">
          <swiper dir="rtl" class="mySwiper" @swiper="onSwiper" :options="swiperOption" :spaceBetween="100"
            :centeredSlides="true" :loop="true" :navigation="false" :modules="modules" :autoplay="{
              delay: 5000,
              disableOnInteraction: false,
            }" :pagination="false">
            <swiper-slide v-for="img in images" :key="img.id">
              <div class="image position-relative">
                <img :srcset="img.image" :alt="img.id" class="w-100 h-100" />
              </div>
            </swiper-slide>
          </swiper>
          <!-- Start Navigation -->
          <div class="navigations d-flex align-items-center">
            <!-- ### Prev Button ### -->
            <div class="prev-btn">
              <div class="
                  w-100
                  h-100
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <span class="d-flex align-items-center justify-content-center" @click="slidePrev">
                  <font-awesome-icon icon="fa-solid fa-angle-right" />
                </span>
              </div>
            </div>
            <!-- ### Next Button #### -->
            <div class="next-btn">
              <div class="
                  w-100
                  h-100
                  d-flex
                  align-items-center
                  justify-content-center
                ">
                <span class="d-flex align-items-center justify-content-center" @click="slideNext">
                  <font-awesome-icon icon="fa-solid fa-chevron-left" />
                </span>
              </div>
            </div>
          </div>
          <!-- End Navigation -->
        </div>
        <!-- End Image Carousel -->
      </div>
    </div>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "../../assets/css/landing-carousel.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        onSlideChangeEnd: function () {
          this.onSwipe();
        },
      },
      title: "  خبراء في الميدان",
      subTitle: "الفريق الذي سيحقق أحلامك",
      description:
        " لدينا فريق من المهنيين ذوي المهارات العالية القادرة على تطوير التطبيق أو الويب المثالي من خلال استخدام العمل الجماعي الذي سيتجاوز كل توقعاتك",
      images: [
        {
          id: "1",
          image: "/images/experts/ex1.jpg",
        },
        {
          id: "2",
          image: "/images/experts/ex2.jpg",
        },
        {
          id: "3",
          image: "/images/experts/ex3.jpg",
        },
      ],
      next: false,
      prev: false,
      swiper: null,
    };
  },
  setup() {
    return {
      modules: [Navigation, Pagination, Autoplay],
    };
  },
  computed: {},
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    slideNext() {
      this.swiper.slideNext();
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
  },
};
</script>

<style>
.experts {
  padding-top: 100px;
  padding-bottom: 60px;
}

h1 {
  margin: 25px 0;
  font-weight: bold;
  font-size: 42px;
  color: var(--header-color);
}

p {
  font-size: 18px;
  color: grey;
  line-height: 1.8;
  text-align: justify;
}

.experts .image {
  width: 650px;
  height: 550px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: auto;
  margin-left: auto;
}

.experts .navigations {
  position: absolute;
  bottom: -45px;
  left: 15%;
  z-index: 1;
}

.experts .navigations .next-btn,
.experts .navigations .prev-btn {
  position: relative;
  background-color: var(--primary-color);
  height: 90px;
  width: 90px;
  margin: 0;
  border: 0;
}

.experts .navigations span {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.2);
  -webkit-transition: 0.3s;
  transition: 0.3s ease;
  cursor: pointer;
}

.experts .navigations span:hover {
  background-color: #fff;
  color: var(--primary-color);
}

.experts .navigations .next-btn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  height: 67px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 9999;
}

.experts .navigations .prev-btn {
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}

@media (max-width: 1024px) {
  .experts .box {
    margin-top: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1024px) {
  .experts .image {
    width: 800px;
  }
}

@media (max-width: 768px) {
  .experts .image {
    width: 500px;
    height: 600px;
  }

  .experts h1 {
    font-size: 26px;
  }
}

@media (max-width: 480px) {
  .experts .image {
    width: 300px;
    height: 400px;
  }

  .experts .navigations {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 300px) {
  .experts .image {
    width: 260px;
    height: 340px;
  }
}
</style>
