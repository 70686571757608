<template>
  <section>
    <div class="container">
      <div class="d-flex flex-column">
        <img src="/images/contact.svg" class="d-block mx-auto mb-3" />
        <p class="text-black text-center fw-bold mb-3">
          شكراً لتواصلك معنا سيتم التواصل معك بأقرب وقت
        </p>
        <router-link to="/home" class="btn btn-secondary px-5 py-2 my-3"
          >الصفحة الرئيسية</router-link
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.container div {
  width: 100%;
  max-width: 550px;
  margin: 5rem auto;
  padding: 3rem;
  box-shadow: 0px 10px 50px rgba(70, 113, 180, 0.1);
  border-radius: 5px;
}
</style>