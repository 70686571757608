<template>
    <div v-if="!isLoading" class=" d-flex align-items-center justify-content-center">
        <div>
            <img src="/images/Mootawer-gif.gif" alt="loader" class=" img-fluid">
        </div>
    </div>
</template>

<script>
export default {
    props: ["isLoading"]
};
</script>

<style scoped>
.loader {
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow: hidden;
    background-color: #fff;
}

img {
    width: 100%;
    height: 100vh;
}
</style>
