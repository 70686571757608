<template>
  <!-- Start Navbar -->
  <nav class="nav-bar py-2 w-100" :style="{ background: navColor }">
    <div class="progress" :style="{width: progress}"></div>
    <div class="container-fluid position-relative w-100 h-100">
      <div class="d-flex align-items-center justify-content-between w-100 h-100">
        <router-link to="/home">
          <img src="/images/logo.png" alt="Mootawer-logo" />
        </router-link>
        <button type="button" @click="showDialog" class="border-0 bg-transparent">
          <div class="dots d-flex flex-wrap align-items-center w-100 h-100" @mouseenter="setOpacityIn"
            @mouseleave="setOpacityOut">
            <span class="qodef-dot" :class="[dotsColor]"></span>
            <span class="qodef-dot opacity-dot" :class="[dotsColor, animateDot]"
              style="animation-duration: 0.8s"></span>
            <span class="qodef-dot opacity-dot" :class="[dotsColor, animateDot]"
              style="animation-duration: 0.6s"></span>
            <span class="qodef-dot" :class="dotsColor"></span>
            <span class="qodef-dot" :class="dotsColor"></span>
            <span class="qodef-dot opacity-dot" :class="[dotsColor, animateDot]"
              style="animation-duration: 0.4s"></span>
            <span class="qodef-dot" :class="dotsColor"></span>
            <span class="qodef-dot" :class="dotsColor"></span>
            <span class="qodef-dot" :class="dotsColor"></span>
          </div>
        </button>
      </div>
    </div>
  </nav>
  <!-- End navbar -->
  <nav-modal @close="hideDialog" :open="dialogIsVisible"> </nav-modal>
</template>

<script>
// import TheLinks from './TheLinks.vue';
import NavModal from "./NavModal.vue";
export default {
  components: {
    NavModal,
  },
  data() {
    return {
      isMouseEnter: false,
      isMouseLeave: false,
      dialogIsVisible: false,
      isWhite: false,
      currentUrl: "",
      prevScrollpos: null,
      isHome: true,
      progress: "0%"
    };
  },
  computed: {
    animateDot() {
      return {
        "opacity-in": this.isMouseEnter,
        "opacity-out": this.isMouseLeave,
      };
    },
    dotsColor() {
      if (this.isWhite) {
        return "bg-white";
      } else {
        return "bg-dark";
      }
    },
    navColor() {
      if (this.isHome && this.prevScrollpos == 0) {
        return "transparent";
      } else {
        return "#fff";
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateProgressIndicator);
  },
  created() {
    // Check Page Path
    this.currentUrl = window.location.pathname;
    if (this.currentUrl === "/home" || this.currentUrl === "/") {
      this.isWhite = true;
      this.isHome = true;
    } else {
      this.isWhite = false;
      this.isHome = false;
    }

    // Scroll
    this.prevScrollpos = window.pageYOffset;
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    setOpacityIn() {
      this.isMouseEnter = true;
      this.isMouseLeave = false;
    },
    setOpacityOut() {
      this.isMouseEnter = false;
      this.isMouseLeave = true;
    },
    showDialog() {
      this.dialogIsVisible = true;
    },
    hideDialog() {
      this.dialogIsVisible = false;
    },
    handleScroll() {
      var currentScrollPos = window.pageYOffset;
      var nav = document.querySelector(".nav-bar");
      if (this.prevScrollpos > currentScrollPos) {
        nav.style.top = "0";
        nav.style.background = "#fff";
        this.isWhite = false;
      } else {
        nav.style.top = "-100px";
        this.isWhite = true;
      }
      // Check Is Home Page
      if (this.isHome && this.prevScrollpos < 70) {
        nav.style.background = "transparent";
        this.isWhite = true;
        // console.log("ishome : ", this.isHome);
      } else {
        this.isWhite = false;
        // console.log("ishome : ", this.isHome);
      }
      this.prevScrollpos = currentScrollPos;
    },
    updateProgressIndicator() {
      const { documentElement, body } = document;
      let windowScroll = body.scrollTop || documentElement.scrollTop;
      let height = documentElement.scrollHeight - documentElement.clientHeight;
      this.progress = (windowScroll / height) * 100 + "%";
    }
  },
  watch: {
    $route(to) {
      // console.log("to" + to.path);
      // console.log(from);
      const currentPage = to.path;
      if (currentPage === "/" || currentPage === "/home") {
        // console.log("menu Should Be White");
        this.isWhite = true;
        this.isHome = true;
      } else {
        this.isWhite = false;
        this.isHome = false;
      }
    },
  },
};
</script>

<style scoped>
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  z-index: 99;
  transition: all 0.9s ease;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: var(--primary-color);
}

button {
  width: 24px;
  height: 24px;
}

.qodef-dot {
  width: 6px;
  height: 6px;
  margin: 0 2px 2px 0;
  border-radius: 50%;
  visibility: visible;
  padding: 0;
  display: block;
}

.opacity-in {
  -webkit-animation-name: opacityIn;
  animation-name: opacityIn;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.opacity-out {
  -webkit-animation-name: opacityOut;
  animation-name: opacityOut;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

@keyframes opacityIn {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 0.8;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}

@keyframes opacityOut {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.2;
  }

  60% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}
</style>
