<template>
    <section class="development">
        <div class="container">
            <div class="row flex-xl-row flex-column-reverse">
                <!-- Start Accordions -->
                <div class="col-xl-6 col-lg-12">
                    <div class="box" style="height: auto;">
                        <div class="accordion">
                            <div class="accordion-item" v-for="(item,index) in items" :key="item.id" data-aos="fade-up"
                                data-aos-easing="ease" :data-aos-delay="400 + index*200">
                                <h2 class="accordion-header" :id="'label'+index">
                                    <button class="accordion-button" :class="{ 'collapsed': index !== 0 }" type="button"
                                        data-bs-toggle="collapse" :data-bs-target="'#' + 'target' + index"
                                        aria-expanded="true" :aria-controls="'target' + index">
                                        {{ item.title }}
                                    </button>
                                </h2>
                                <div :id="'target' + index" class="accordion-collapse collapse"
                                    :class="{ 'show': index === 0 }" :aria-labelledby="'label'+index">
                                    <div class="accordion-body">
                                        {{ item.description }}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- End Accordions -->

                <!-- Start Image -->
                <div class="col-xl-6 col-lg-12" data-aos="fade-left">
                    <div class="image">
                        <img src="/images/design2.png" alt="" class="w-100 h-100">
                    </div>
                </div>
                <!-- End Image -->
            </div>
        </div>
    </section>
    <!-- End Development -->
</template>

<script>
export default {
    data() {
        return {
            items: [
                {
                    id: 1,
                    title: 'تصميم واجهة المستخدم',
                    description: 'يعمل تصميم واجهة مستخدم التطبيق بتحسين تجربة ورضا العملاء مما يساعد في النهاية على زيادة هائلة في عدد مستخدمي التطبيق'
                },

                {
                    id: 2,
                    title: 'الاستضافة',
                    description: ' تحتاج إلى استضافة ويب لتخزين ملفات موقع الويب الخاص بك نقدم خيارات متنوعة تتناسب باختلاف نوع مشروعك و حجمه بحيث يمكن لأي شخص الوصول إلى موقع الويب الخاص بك'
                },
                {
                    id: 3,
                    title: 'تطوير المواقع الإلكترونية',
                    description: 'سنقوم بتطوير وتصميم موقع الويب الخاص بك بطريقة جذابة بحيث يكون عملاؤك راضين دائمًا وسيكون بإمكانهم العثور على ما يحتاجون إليه بسهولة',
                },
                {
                    id:4,
                    title: 'تطوير التطبيقات',
                    description: 'سنقوم بتطوير وتصميم تطبيقاتكم حتى يتمكن أي عميل من خلال هاتفه الحصول على ما يحتاج إليه في أي وقت ومكان'
                },

            ]
        }
    },
}
</script>

<style scoped>
.development {
    background-color: #fff;
    padding-bottom: 60px;
    min-height: 880px;
}

.development .accordion-item {
    border-width: 1px !important;
    border-color: transparent transparent #e1e1e1 transparent !important;
    border-style: solid !important;
}

.development .accordion-button {
    color: var(--header-color);
    font-size: 19px;
    font-weight: 700;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    height: 70px;
}

.development .accordion-button:hover {
    color: var(--primary-color) !important;
}

.development .accordion-button:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.development .accordion-button:not(.collapsed) {
    color: var(--primary-color) !important;
    background-color: #fff !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    font-weight: bold;
}

.development .accordion-button:not(.collapsed)::after,
.development .accordion-button::after {
    font: var(--fa-font-solid);
    font-size: 14px;
    font-weight: bold;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-image: none !important;
}

.development .accordion-button:not(.collapsed)::after {
    content: "\f068";
    color: var(--primary-color);
}

.development .accordion-button::after {
    content: '\2b';
    color: #000;
}

.development .image {
    width: 600px;
    margin: 0 auto;
}

.development .box {
    padding-top: 200px;
}

@media (max-width: 1024px) {
    .development .box {
        padding-top: 0;
    }

    .development .image {
        width: 650px;
        margin: 0 auto 30px;
    }
}

@media (max-width: 540px) {
    .development .image {
        width: 340px;
        margin: 30px auto;
    }
}

@media (max-width: 480px) {
    .development .image {
        width: 280px;
        margin: 30px auto;
    }
}

@media (max-width: 280px) {
    .development .image {
        width: 260px;
    }
}

</style>