<template>
    <teleport to="body">
        <transition name="fade">
            <div class="pagetop" v-show="isScroll > 500" @click="toTop">
                <div class="d-flex align-items-center justify-content-center w-100 h-100">
                    <font-awesome-icon class="d-block" icon="fa-solid fa-caret-up" />
                </div>
            </div>
        </transition>

        <!-- <transition name="fade">
            <div class="wts-link" v-show="isScroll > 500">
                <div class="d-flex align-items-center justify-content-center w-100 h-100">
                    <a href=" https://wa.me/+96181230256">
                        <font-awesome-icon icon="fa-brands fa-whatsapp" />
                    </a>
                </div>
            </div>
        </transition> -->

        <a href=" https://wa.me/+96181230256" class="waves d-flex align-items-center text-white gap-2" ref="whatsapp">
            <font-awesome-icon icon="fa-brands fa-whatsapp" />
            <span> أي أسئلة ؟ اسأل في الواتساب</span>
        </a>
        <transition name="slide-fade">
            <div v-if="showMsg" class="initial-message d-flex gap-2" @click="$refs.whatsapp.click()">
                <img class="mt-2" src="../../../public/images/favicon.png" alt="">
                <div class="d-flex flex-column align-items-start">
                    <span class="fw-bold">مُطوِّر</span>
                    <span>خدمة العملاء</span>
                    <span>مرحبًا ، اطرح سؤالك ، و سوف نرد في أسرع وقت ممكن.</span>
                </div>
                <div @click.stop="showMsg = false" class="close">x</div>
            </div>
        </transition>
    </teleport>
</template>

<script>
export default {
    data() {
        return {
            isScroll: 0,
            showMsg: false
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        setTimeout(() => {
            this.showMsg = true
        }, 2000);
    },
    methods: {
        handleScroll() {
            this.isScroll = window.scrollY;
        },
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
}
</script>

<style scoped>
.pagetop,
.wts-link {
    position: fixed;
    bottom: 20px;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    cursor: pointer;
}

.pagetop {
    left: 30px;
    background-color: var(--primary-color);
}

.pagetop svg {
    color: #fff;
    font-size: 17px;
    transition: 0.3s ease;
}

.pagetop:hover svg {
    margin-bottom: 6px;
}

.wts-link {
    right: 30px;
    background-color: #25d366;
}

.wts-link a {
    color: #fff;
    font-size: 25px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.waves {
    position: fixed;
    right: 25px;
    bottom: 25px;
    height: 42px;
    padding: 6px 20px;
    font-size: 16px;
    background: #30bf39;
    border-radius: 40px;
    box-shadow: 0 1px 6px 0 rgb(0 0 0 / 6%), 0 2px 32px 0 rgb(0 0 0 / 16%);
    z-index: 9999;
    cursor: pointer;
    transition: opacity 200ms linear;
    animation: waves-whatsapp linear 3s infinite;
}

.waves:hover {
    opacity: 0.83;
}

.waves::before {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    background: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

@keyframes waves-whatsapp {

    0% {
        box-shadow: 0 8px 10px rgb(48 191 57 / 30%), 0 0 0 0 rgb(48 191 57 / 20%), 0 0 0 0 rgb(48 191 57 / 20%);
    }


    40% {
        box-shadow: 0 8px 10px rgb(48 191 57 / 30%), 0 0 0 35px rgb(48 191 57 / 20%), 0 0 0 0 rgb(48 191 57 / 20%);
    }

    80% {
        box-shadow: 0 8px 10px rgb(48 191 57 / 30%), 0 0 0 55px rgb(48 191 57 / 0%), 0 0 0 26.7px rgb(48 191 57 / 7%);
    }

    100% {
        box-shadow: 0 8px 10px rgb(48 191 57 / 30%), 0 0 0 80px rgb(48 191 57 / 0%), 0 0 0 40px rgb(48 191 57 / 0%);
    }
}

.waves svg {
    font-size: 21px;
    animation: sm-shake-animation linear 1.5s infinite;
}

@keyframes sm-shake-animation {
    0% {
        transform: rotate(0) scale(1) skew(0.017rad);
    }

    25% {
        transform: rotate(0) scale(1) skew(0.017rad);
    }

    35% {
        transform: rotate(-0.3rad) scale(1) skew(0.017rad);
    }

    45% {
        transform: rotate(0.3rad) scale(1) skew(0.017rad);
    }

    55% {
        transform: rotate(-0.3rad) scale(1) skew(0.017rad);
    }

    65% {
        transform: rotate(0.3rad) scale(1) skew(0.017rad);
    }

    75% {
        transform: rotate(0) scale(1) skew(0.017rad);
    }

    100% {
        transform: rotate(0) scale(1) skew(0.017rad);
    }
}

.initial-message {
    position: fixed;
    right: 30px;
    bottom: 80px;
    background: white;
    box-shadow: 5px 0px 10px rgb(0 0 0 / 50%);
    border-radius: 6px;
    width: 400px;
    min-height: 77px;
    padding: 6px 12px;
    transition: background-color 150ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    cursor: pointer;
    z-index: 9999;
}

.initial-message:hover {
    background-color: #f1f1f1;
}

.initial-message .close {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    font-size: 19px;
    color: #000;
}

.initial-message img {
    width: 40px;
    height: 40px;
}

.initial-message .flex-column span {
    color: #161c2d;
    font-size: 14px;

}

.initial-message .flex-column span:nth-child(2) {
    opacity: .6;
    font-size: 10px;
}

/* Slide Fade */
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(20px);
    opacity: 0;
}

@media (max-width:540px) {
    .initial-message {
        right: unset;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
    }
}
</style>