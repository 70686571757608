import { createStore } from "vuex";

import portfolioModule from './modules/portfolio/index.js';
import socialMediaModule from './modules/socialMedia/index.js';
import blogModule from './modules/blog/index.js';

const store = createStore({
    modules: {
        portfolios: portfolioModule,
        social: socialMediaModule,
        blogs: blogModule
    },
    state() {
        return {

        }
    },

});

export default store;