<template>
    <div>
        <router-link v-if="!button" :to="to" class="move" data-aos="fade-up" data-aos-easing="ease"
            data-aos-delay="1000">
            <slot></slot>
            <font-awesome-icon icon="fa-solid fa-arrow-left-long" />
        </router-link>

        <button v-else class="move large-btn" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="1000">
            <slot></slot>
            <font-awesome-icon icon="fa-solid fa-arrow-left-long" />
        </button>

    </div>
</template>

<script>
export default {
    props: {
        to: {
            type: String,
            required: false,
            default: '/'
        },
        button: {
            type: Boolean,
            required: false,
            default: false
        },
    }
}
</script>

<style scoped>
a {
    color: var(--header-color);
    font-weight: 400;
    font-size: 18px;
}

a svg,
button svg {
    margin-right: 15px;
    border: 0;
}

a.move:hover svg ,
button.move:hover svg{
    -webkit-animation: moveOpacity 1.5s forwards;
    animation: moveOpacity 1.5s forwards;
}

@-webkit-keyframes moveOpacity {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    30% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    60% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes moveOpacity {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    30% {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }

    60% {
        opacity: 0;
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
</style>