<template>
  <section class="our-works">
    <div class="container">
      <div class="row">
        <!-- Start Description -->
        <div class="col-xl-4 col-sm-12">
          <div class="text">
            <base-heading animate>خبراء في الميدان</base-heading>
            <h1
              class="mt-3"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="100"
            >
              دعنا نتكلم بالأرقام
            </h1>
          </div>
        </div>
        <!-- End Description -->

        <!-- Start Our Work Counter -->

        <!-- Count 1  -->
        <div class="col-xl-2 col-sm-12">
          <div class="box text-center">
            <h2>تطبيقات مطورة</h2>
            <vue3-autocounter
              class="countNumbers"
              ref="app"
              :startAmount="0"
              :endAmount="23"
              :duration="2"
            />
          </div>
        </div>

        <!-- Count 2 -->
        <div class="col-xl-2 col-sm-12">
          <div class="box text-center">
            <h2>مواقع مطورة</h2>
            <vue3-autocounter
              class="countNumbers"
              ref="dev"
              :startAmount="0"
              :endAmount="50"
              :duration="2"
            />
          </div>
        </div>

        <!-- Count 3 -->
        <div class="col-xl-2 col-sm-12">
          <div class="box text-center">
            <h2>دول عملنا معها</h2>
            <vue3-autocounter
              class="countNumbers"
              ref="countries"
              :startAmount="0"
              :endAmount="6"
              :duration="2"
            />
          </div>
        </div>

        <!-- Count 4 -->
        <div class="col-xl-2 col-sm-12">
          <div class="box text-center">
            <h2>مواقع مستضافة</h2>
            <vue3-autocounter
              class="countNumbers"
              ref="websites"
              :startAmount="0"
              :endAmount="70"
              :duration="2"
            />
          </div>
        </div>
      </div>
      <!-- End  Our Work Counter -->
    </div>
  </section>
</template>

<script>
import Vue3Autocounter from "vue3-autocounter";
export default {
  components: {
    Vue3Autocounter,
  },
  data() {
    return {
      Scrolled: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (!this.Scrolled) {
        var element = document.querySelector(".our-works");
        if (window.scrollY >= element.offsetTop - window.innerHeight + 100) {
          this.$refs.app.start();
          this.$refs.dev.start();
          this.$refs.countries.start();
          this.$refs.websites.start();
          this.Scrolled = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.our-works {
  padding: 60px 0;
}

.our-works .text h1 {
  font-weight: 800;
  font-size: 42px;
  line-height: 1.1em;
  color: var(--header-color);
}

.our-works .box h2 {
  color: #000;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 30px;
}

.our-works .box span {
  height: 1em;
  display: inline-block !important;
  vertical-align: middle;
  color: #e5e5e5;
  font-size: 90px;
  line-height: 0.97em;
  font-weight: 400;
  font-family: Merriweather, serif;
}

@media (max-width: 1024px) {
  .our-works .text h1 {
    width: 300px;
    margin: 30px auto 50px;
    font-size: 22px;
    text-align: center;
  }

  .our-works .box {
    margin-bottom: 50px;
  }
}

@media (max-width: 280px) {
  .our-works .text h1 {
    width: 260px;
  }
}
</style>