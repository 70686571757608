<template>
    <div class="view-more m-5 text-center" data-aos="flip-down" data-aos-delay="400">
        <router-link :to="to">
            شاهد المزيد
        </router-link>
    </div>

</template>

<script>
export default {
    props: {
        to: {
            type: String,
            required: false,
            default: '/'
        }
    }
}
</script>

<style scoped>
.view-more a {
  position: relative;
  overflow: hidden !important;
  padding: 0;
  padding-bottom: 5px;
  color: #000;
  font-weight: bold;
}

.view-more a:after {
  content: "";
  background: var(--primary-color);
  height: 2px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  width: 0%;
}

.view-more a:hover::after {
  -webkit-animation: link-hoverstate 0.5s linear forwards;
          animation: link-hoverstate 0.5s linear forwards;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
          animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden !important;
}

@-webkit-keyframes link-hoverstate {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  55% {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    width: 100%;
  }
  56% {
    -webkit-transform: translate3d(100%, calc(0.5rem + 4px), 0);
            transform: translate3d(100%, calc(0.5rem + 4px), 0);
    width: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, calc(0.5rem + 4px), 0);
            transform: translate3d(0, calc(0.5rem + 4px), 0);
    width: 100%;
  }
}

@keyframes link-hoverstate {
  0% {
    opacity: 1;
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
  55% {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
    width: 100%;
  }
  56% {
    -webkit-transform: translate3d(100%, calc(0.5rem + 4px), 0);
            transform: translate3d(100%, calc(0.5rem + 4px), 0);
    width: 0;
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, calc(0.5rem + 4px), 0);
            transform: translate3d(0, calc(0.5rem + 4px), 0);
    width: 100%;
  }
}
</style>