<template>
    <section class="experts bg-white">
        <div class="container">
            <div class="row align-items-center flex-wrap-reverse">
                <div class="col-12 col-md-6">
                    <base-heading animate>خبراء في البرمجة</base-heading>
                    <h1 data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">أحدث وسائل التطوير التكنولوجية</h1>
                    <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">لدينا فريق من المهنيين ذوي المهارات العالية القادرة على تطوير التطبيق أوالويب
                        المثالي من خلال استخدام العمل الجماعي الذي سيتجاوز كل توقعاتك</p>
                </div>
                <div class="col-12 col-md-6 text-center">
                    <img src="/images/techno.png" alt="techno" class="img-fluid mb-5 mb-md-0" data-aos="flip-up" data-aos-easing="ease" data-aos-delay="800">
                </div>
            </div>
        </div>
    </section>
</template>
    
