<template>
    <div class="text">
        <p data-aos="fade-up" class="mb-0">
            أصبح حضور الشركات سواء الكبيرة أو المتوسطة وصغيرة الحجم على الإنترنت أمرًا حتميّا، بل أصبح
            ذلك الحضور رأس مال الكثير منها، يبدأ المستخدمون بالبحث عن موقع شركتك الإلكتروني لمعرفة
            المزيد من المعلومات عن علامتك التجارية وخدماتك، أو للبحث عن طرق التواصل معك، لذلك إذا لم تقم
            بعد بإنشاء موقع إلكتروني لشركتك فأنت ترتكب خطأ كبيرا وتتأخر عن منافسيك خطوات كبيرة، وتغامر
            بخسارة الكثير من العملاء المحتملين، إذا كنت تعتقد أنه ما من داع لإنشاء موقع إلكتروني أو
            التواجد على الإنترنت فإن هذه الإحصائيات التي أفصح عنها موقع ويبلي العملاق الذي قام ببناء 15
            مليون موقع عالمي؛ ستفتح عينيك على الحقيقة:
        </p>
        <p class="mt-0" data-aos="fade-up">
            <br /> <span class="fw-bold">%56</span> من المستهلكين لا يثقون بعملك ما لم يكن لديك موقع
            إلكتروني
            <br /> <span class="fw-bold">%98</span> من أصحاب الأعمال يقولون أن صفحة على موقع فيس بوك
            للشركة ليست بديلا عن الموقع
            الإلكتروني
            <br /><span class="fw-bold"> %58</span> من الناس يعتقدون أن امتلاك موقع إلكتروني يُعدّ مفتاح
            النجاح عندما تبدأ أيّ شيء
            <br> <span class="fw-bold">%60 </span> من العملاء يصابون بالإحباط من المواقع التي لا تعمل
            جيدا على الهاتف الذكي أو الأجهزة
            اللوحية
        </p>
        <h3 class="fw-bold mt-3" data-aos="fade-up">
            لماذا وجودك على الإنترنت يعتبر ضروريا لأعمالك؟
        </h3>
        <ol class="list-group list-group-numbered">
            <li class="list-group-item  d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">الوصول إلى جمهور أكبر:</div>
                    <p class="mb-0">
                        أزيد من 88% من مستخدمي الإنترنت يبحثون على محركات البحث عن المواقع والمنتجات
                        والخدمات، يمكن أن تغلق مكاتب الشركة في الأوقات المحددة لإنصراف الموظفين ولكن
                        الموقع الإلكتروني هو مكتب مفتوح 7/24 ومصدر كبير للمعلومات، يسمح للزوار بالوصول
                        الفوري لخدماتك ومنتجاتك، ومعرفة المزيد عن شركتك والاقتراب من نشاطاتها، والاطلاع
                        على العروض، وقراءة المقالات والتعليق وكتابة المراجعات وما إلى ذلك
                    </p>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">تثقيف عملائك حول علامتك التجارية: </div>
                    <p class="mb-0">
                        الإنترنت هو المكان الأول الذي يتجه إليه الناس الآن لمعرفة ما يريدون معرفته عن
                        العلامات التجارية، وستتمكن من خلاله من الوصول إلى المجتمع المحلي والعالمي دون أن
                        تتقيّد بأي جغرافيا، أو توقيت
                    </p>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold"> تسويق بمجهود أقلّ :</div>
                    <p class="mb-0">
                        الترويج للخدمات والمنتجات عن طريق نشر محتوى مكتوب ومصور (فيديو وصور) يوضح
                        للعملاء خدماتك، وأوصاف أو حتى طريقة استخدام منتجاتك، والسماح للعملاء بالعثور على
                        إجابات لأسئلتهم حول المنتج في الموقع. إضافة إلى خدمة الدعم الفني المباشر تعدّ من
                        الطرق القويّة لدعم إستراتيجيتك التسويقية، وزيادة نسب المبيعات، كما أن المراجعات
                        التي يكتبها العملاء على موقعك وتقييمهم للمنتج يشجع العملاء المحتملين في اتخاذ
                        قرارات الشراء
                    </p>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold"> بناء مجتمع وفيّ لعلامتك التجارية :</div>
                    <p class="mb-0">
                        الحضور القوي على الإنترنت من خلال تقديم محتوى مفيد وجذاب يخلق نخبة من العملاء
                        الأوفياء والدائمين لعلامتك التجارية، ويمنح عملك المزيد من المصداقية، ويساهم في
                        بناء سمعة قوية لأعمالك ما يمنح العملاء والمستثمرين أو حتى الموظفين المحتملين
                        انطباعا عن أنك تقوم بعمل جاد
                    </p>
                </div>
            </li>
        </ol>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            كيف تعزز وجود أعمالك على شبكة الإنترنت؟
        </h3>
        <ol class="list-group list-group-numbered">
            <li class="list-group-item  d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">بناء موقع إلكتروني : </div>
                    <p class="mb-0">
                        يسلط الضوء على احترافية وجودة الخدمات والمنتجات التي تقدمها شركتك، عملية إنشاء
                        موقع ليس بالأمر الصعب ولكن ما من فائدة في إنشائه وبجودة عالية، ما لم يتمكن
                        المستخدمون من الوصول إليه وسط ملايين المواقع
                    </p>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">كن متواجدا على الشبكات الاجتماعية:</div>
                    <p class="mb-0">
                        من المؤكد أن شبكات التواصل هي أداة تسويقية هامة لكل الأعمال التجارية ولكن ستكون
                        بعض القنوات أكثر فعالية من غيرها، ومفتاح النجاح يكمن في اختيار الوسيلة المناسبة،
                        إذا كان عملك مثلا في مجال المطاعم فإن إنستغرام هو المنصة رقم #1 التي ستساعدك في
                        تعزيز نشاطك التجاري، ويمكن أن تكون صاحب مؤسسة تدريب وتعليم لذلك يعدّ لينكد إن
                        خياراً مثالياً، حيث يلتقي رجال الأعمال والموظفون والكثير من المؤثرين وصناع
                        القرار في عالم المال والاقتصاد.
                    </p>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">اتجه نحو التدوين: </div>
                    <p class="mb-0">
                        يمنح علامتك التجارية صوتاً مسموعاً على الإنترنت، ويرفع مستوى ثقة عملائك بما
                        تقدمه من خدمات و يمنحك مزيداً من المصداقية والسّمعة، هناك أشكال متعددة من
                        التدوين منها: كتابة المقالات، التدوين بالفيديو، إجراء ونشر دراسات وأبحاث تخص
                        مجال عملك، نشر كتب إلكترونية ذات صلة، استضافة دورات تدريبية وورش عمل وندوات عبر
                        الإنترنت.. إلخ
                    </p>
                </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start" data-aos="fade-up">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">البريد الإلكتروني:</div>
                    <p class="mb-0">
                        عكس ما يعتقده الكثيرون مازال التسويق بالبريد الإلكتروني وإرسال النشرات البريدية
                        للمستخدمين، وسيلة فعالة للحصول على العملاء وتذكير العملاء الحاليين بوجودك
                        والترويج لمنتجاتك وخدماتك، وتزويد العملاء بالمعلومات والعروض الجديدة، ومنحك فرص
                        لشراكات جديدة واستثمارات واعدة، وتوسيع شبكتك و قائمة اتصالاتك والوصول إلى جمهور
                        أوسع يوميا، لذلك لا ينبغي أن تفوتها لتعزيز وجود شركتك على الإنترنت.
                    </p>
                </div>
            </li>
        </ol>

        <h5 class="fw-bold mt-5" data-aos="fade-up">
            <i class="fas fa-pencil-alt me-2"></i>أبرز اقوال <span class="author">"جو بوليتزي"</span>
        </h5>
        <blockquote data-aos="fade-down">
            اعثر على مكانك الرائع: نقطة الالتقاء بين ما تعرفه وما يحتاج عملاؤك إلى معرفته
        </blockquote>
    </div>
</template>