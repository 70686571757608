<template>
  <transition name="modal">
    <div v-if="open" class="nav-modal py-2">
      <div class="container-fluid w-100 h-100">
        <div class="nav-header d-flex align-items-center justify-content-between">
          <router-link to="/home"  @click="$emit('close')">
            <img src="/images/logo.png" alt="Mootawer-logo" />
          </router-link>
          <button type="button" @click="$emit('close')"
            class="btn-close d-flex align-items-center justify-content-center">
            <span class="text-primary d-block fs-4 opacity-100">X</span>
          </button>
        </div>
        <div class="row nav-body overflow-hidden">
          <div class="col-6 px-0">
            <div class="d-flex flex-column gap-3 gap-md-5">
              <div class="link-holder mb-2 mb-md-0" :class="{ moveLinks: open }">
                <router-link to="/home" @click="$emit('close')" class="large-links d-flex align-items-center gap-2">
                  الرئيسية
                  <font-awesome-icon icon="fa-solid fa-arrow-left-long" class="arrow" />
                </router-link>
              </div>

              <div class="link-holder mb-2 mb-md-0" :class="{ moveLinks: open }" style="animation-delay: 0.2s">
                <router-link to="/blogs" @click="$emit('close')" class="large-links d-flex align-items-center gap-2">
                  المدونة
                  <font-awesome-icon icon="fa-solid fa-arrow-left-long" class="arrow" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-6 px-0">
            <div class="d-flex flex-column gap-3 gap-md-5">
              <div class="link-holder mb-2 mb-md-0" :class="{ moveLinks: open }" style="animation-delay: 0.4s">
                <router-link to="/portfolios" @click="$emit('close')"
                  class="large-links d-flex align-items-center gap-2">
                  اعمالنا
                  <font-awesome-icon icon="fa-solid fa-arrow-left-long" class="arrow" />
                </router-link>
              </div>

              <!-- <div class="link-holder mb-2 mb-md-0" :class="{ moveLinks: open }" style="animation-delay: 0.6s">
                <router-link to="/contact" @click="$emit('close')" class="large-links d-flex align-items-center gap-2">
                  اتصل بنا
                  <font-awesome-icon icon="fa-solid fa-arrow-left-long" class="arrow" />
                </router-link>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row nav-footer">
          <div class="col-12 col-lg-2"></div>
          <div class="col-12 col-md-8 col-lg-3">
            <div class="d-flex align-items-center justify-content-around justify-content-lg-between">
              <div v-for="(social , index) in socialMedia" :key="social.id" class="link-holder" :class="{ moveLinks: open }"
                :style="{animationDelay : 0.65 + index * 0.05 + 's' }">
                <a :href="social.link" class="large-links">
                  <font-awesome-icon :icon="social.icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["open"],
  emits: ["close"],
  computed:{
    socialMedia(){
      return this.$store.getters['social']
    }
  }
};
</script>

<style scoped>
.nav-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #141414;
  background-image: url(../../../../public/images/fullscreen-background.jpg);
  background-position: center 0;
  background-repeat: no-repeat;
  z-index: 9999;
}

.modal-enter-active {
  animation: modal 0.3s ease-out;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

@keyframes modal {
  from {
    opacity: 0;
    top: -100%;
  }

  to {
    opacity: 1;
    top: 0;
  }
}
.nav-header{
  height: 70px;
}
.nav-body {
  margin: 8% 0;
}

.link-holder {
  opacity: 0;
  transform: translateX(150px);
}

.moveLinks {
  animation: move 0.5s forwards;
}

@keyframes move {
  from {
    opacity: 0;
    transform: translateX(150px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.large-links {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  width: fit-content;
  transition: 0.3s ease;
}

.large-links .arrow {
  color: var(--primary-color);
  font-size: 37px;
  transform: scale(0);
  transition: 0.3s ease;
}

.large-links:hover,
.large-links:active,
.large-links.router-link-active {
  color: var(--primary-color) !important;
}

.large-links:hover .arrow,
.large-links.router-link-active .arrow {
  transform: scale(1);
}

@media (max-width: 540px) {
  .large-links {
    font-size: 26px;
  }

  .nav-body {
    margin: 20% 0;
  }
}

@media (max-width: 280px) {
  .large-links {
    font-size: 21px;
  }
}
</style>
