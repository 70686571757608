<template>
  <section class="py-5">
    <div class="container">
      <base-title color="white">استشارتك مجانية</base-title>
      <div class="row align-items-center flex-wrap-reverse">
        <div class="col-12 col-md-5">
          <base-heading animate color="white">قسم الاستشارات</base-heading>
          <p
            class="text-white fs-5 fw-bold my-3"
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-delay="600"
          >
            نقدم لكم افضل الاستشارات لخططكم احصل على استشارة مجانية لمدة
            <span class="text-blue"> 30 دقيقة</span>
          </p>
          <a
            href="https://wa.me/+96181230256"
            target="_blank"
            class="large-btn d-block mx-auto me-lg-auto ms-lg-0"
          >
            احجز موعدك
            <font-awesome-icon
              icon="fa-solid fa-arrow-left-long"
              class="ms-3"
            />
          </a>
        </div>
        <div class="col-12 col-md-7 text-center">
          <img
            src="/images/consultation.png"
            alt=""
            class="img-fluid mb-5 mb-md-0"
          />
        </div>
      </div>
    </div>
  </section>
</template>




<style scoped>
section {
  background-color: var(--primary-color);
}
a:hover svg {
  -webkit-animation: moveOpacity 1.5s forwards;
  animation: moveOpacity 1.5s forwards;
}

@-webkit-keyframes moveOpacity {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  30% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveOpacity {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  30% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  60% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
</style>