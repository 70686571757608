// Start Import Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// Start Import Css Bootstarp 5
import "bootstrap/dist/css/bootstrap.rtl.min.css";
// importing AOS css style globally
import "aos/dist/aos.css";
// Start Import Maz Ui css
import "maz-ui/css/main.css";

// Start Import Main Css
import "../src/assets/css/main.css";

// Start Import Router
import router from "./router.js";

// Start Import UI Components
import BaseHeading from "@/components/ui/BaseHeading";
import BaseMoreButton from "@/components/ui/BaseMoreButton";
import BaseBanner from "@/components/ui/BaseBanner";
import BaseTitle from "@/components/ui/BaseTitle";
import BaseArrow from "@/components/ui/BaseArrow";
import BaseShareLarge from "@/components/ui/BaseShareLarge";

// Start Import Vuex
import store from "./store/index.js";
// Import Tel Input
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
// gtag
import { createGtm } from "@gtm-support/vue-gtm";
// import VueAnalytics from 'vue-analytics';

// Start Import Vue App
import { createApp } from "vue";
import App from "./App.vue";

const app = createApp(App);

// Import Used Solid Font Awesome Icons
import {
  faArrowLeftLong,
  faShareNodes,
  faChevronRight,
  faBarsStaggered,
  faChevronLeft,
  faAngleLeft,
  faAngleRight,
  faAnglesDown,
  faCaretUp,
  faEnvelope,
  faComment,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

// Import Used Brand Font Awesome Icons
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

// Add Used Font Awesome Icons
library.add(
  faArrowLeftLong,
  faFacebookF,
  faInstagram,
  faLinkedin,
  faShareNodes,
  faLinkedinIn,
  faChevronRight,
  faBarsStaggered,
  faChevronLeft,
  faCaretUp,
  faWhatsapp,
  faEnvelope,
  faComment,
  faAngleRight,
  faAngleLeft,
  faAnglesDown,
  faCircleExclamation
);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component("base-heading", BaseHeading);
app.component("base-more-button", BaseMoreButton);
app.component("base-banner", BaseBanner);
app.component("base-title", BaseTitle);
app.component("base-arrow", BaseArrow);
app.component("base-share-large", BaseShareLarge);

app.component("MazPhoneNumberInput", MazPhoneNumberInput);

app.use(router);
app.use(store);
app.use(
  createGtm({
    id: "GTM-5JJSF77",
    vueRouter: router,
  })
);
// app.use(VueAnalytics, {
//   id: 'UA-262480876-1',
//   router
// });
app.mount("#app");

// Start Import Js Bootstarp 5
import "bootstrap/dist/js/bootstrap.min.js";
