<template>
  <!-- Start About-us -->
  <section class="about-us" id="about-us">
    <div class="container">
      <div class="row align-items-center flex-column-reverse flex-xl-row">
        <!-- Start Text Box-->
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="text">
            <base-heading animate>خبراء في الميدان</base-heading>

            <h1
              class="text-header my-4"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="400"
            >
              حلول فريدة لأعمالك
            </h1>
            <p
              class="text-parag text-grey"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="600"
            >
              مطور هي وكالة تطوير مقرها بريطانيا و فرعها الإقليمي في لبنان تقدم
              العديد من الخدمات المختلفة ، مثل تطوير الويب والتطبيقات والاستضافة
              وتصميم واجهة المستخدم. نحن نهدف إلى تقديم أفضل الخدمات بأقصى درجات
              الدقة والشفافية والفخر. لقد عملنا على مشاريع في العديد من البلدان
              مثل المملكة العربية السعودية والسويد وسلطنة عمان والمملكة المتحدة
              ولبنان.
            </p>
            <button
              class="large-btn open-form-btn"
              @click="showDialog"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="800"
            >
              اتصل بنا
              <font-awesome-icon
                icon="fa-solid fa-arrow-left-long"
                class="ps-2"
              />
            </button>
            <!-- <div class="writer d-flex justify-content-start align-items-center">
                            <div class="photo">
                                <img src="/images/solution-client.png" alt="About" class=" w-100 h-100">
                            </div>
                            <div class="name">
                                محمد محمد
                            </div>
                            <div class="link">
                                <a href="#" class="text-primary">@</a>
                            </div>
                        </div> -->
          </div>
        </div>
        <!-- End Text Box -->

        <!-- Start Image -->
        <div class="col-xl-6 col-lg-12 col-md-12">
          <div class="image" data-aos="flip-left">
            <img src="/images/solution.png" alt="" class="w-100 h-100" />
          </div>
        </div>
        <!-- End Image -->
      </div>
    </div>
    <keep-alive>
      <contact-modal
        @close="hideDialog"
        :open="dialogIsVisible"
      ></contact-modal>
    </keep-alive>
  </section>
  <!-- End About-us -->
</template>
<script>
import ContactModal from "../contact/ContactModal.vue";
export default {
  components: {
    ContactModal,
  },
  data() {
    return {
      dialogIsVisible: false,
    };
  },
  methods: {
    showDialog() {
      this.dialogIsVisible = true;
    },
    hideDialog() {
      this.dialogIsVisible = false;
    },
  },
};
</script>

  
<style scoped >
.about-us {
  padding-top: 60px;
  padding-bottom: 100px;
}

.text h1 {
  font-size: 50px;
  line-height: 1.1em;
  font-weight: 800;
}

.text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  margin-bottom: 30px;
  text-align: justify;
}

.writer .photo {
  width: 64px;
  height: 64px;
  margin-left: 10px;
}

.text .writer .link a {
  margin-right: 10px;
}

.two .image {
  width: 600px;
}

@media (max-width: 1024px) {
  .text {
    width: 100% !important;
  }

  .image {
    margin: 30px auto 100px;
  }
}

@media (max-width: 540px) {
  .image {
    width: 380px;
  }
}

@media (max-width: 480px) {
  .image {
    width: 280px;
  }
}

@media (max-width: 300px) {
  .image {
    width: 260px;
  }
}
.open-form-btn {
  color: #000 !important;
  font-weight: 700 !important;
  border: 1px solid #e1e1e1 !important;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(50%, #fac400),
    color-stop(50%, white)
  ) !important;
  background: linear-gradient(to left, #fac400 50%, white 50%) !important;
  background-size: 200% 100% !important;
  background-position: left bottom !important;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.open-form-btn:hover {
  background-position: right bottom !important;
  color: white !important;
}

.open-form-btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1x solid #000 !important;
}

.open-form-btn svg {
  margin-right: 20px;
}
</style>