<template>
  <teleport to="body">
    <div v-if="open" @click="preventClose()" class="backdrop"></div>
    <transition name="auth-dialog">
      <dialog open v-if="open" :class="{ preventClose: animate }">
        <div class="position-relative w-100 p-3">
          <button
            type="button"
            class="btn-close"
            @click="$emit('close')"
          ></button>
          <!-- <keep-alive>
                        <contact-form></contact-form>
                    </keep-alive> -->
          <form>
            <div class="d-flex flex-column gap-4 w-100">
              <div class="mt-3 text-center">
                <img
                  src="/images/contact.svg"
                  alt=""
                  class="d-block mx-auto mb-3"
                />
                <h3 class="text-black fw-bold mb-3">تواصل معنا لمساعدتك !</h3>
                <h6 class="text-grey">
                  في حال كان لديك مشروع او فكرة نحن هنا لك ومعك
                </h6>
              </div>
              <div>
                <label for="name" class="form-label"
                  >الاسم
                  <sup>*</sup>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{ error: !nameIsValid && nameFoucsed }"
                  id="name"
                  v-model.trim="form.name"
                  @input="nameFoucsed = true"
                />
                <div
                  v-if="!nameIsValid && nameFoucsed"
                  class="error d-flex align-items-center justify-content-between mb-3"
                >
                  <p class="mb-0">حقل اسم المستخدم مطلوب</p>
                  <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
                </div>
              </div>

              <div>
                <label for="selectBadgets" class="form-label"
                  >رقم الهاتف
                  <sup>*</sup>
                </label>
                <MazPhoneNumberInput
                  :class="{ error: !phoneIsValid && phoneFoucsed }"
                  v-model="form.phone"
                  @input="phoneFoucsed"
                  show-code-on-list
                  color="info"
                  default-country-code="SA"
                  auto-detect
                  @update="results = $event"
                  :success="results?.isValid"
                  :translations="{
                    countrySelector: {
                      placeholder: 'الرقم الدولي',
                      error: 'اختر البلد',
                    },
                    phoneInput: {
                      placeholder: 'رقم الهاتف',
                      example: '',
                    },
                  }"
                />

                <div
                  v-if="!phoneIsValid && phoneFoucsed"
                  class="error d-flex align-items-center justify-content-between mb-3"
                >
                  <p class="mb-0">رقم الهاتف مطلوب</p>
                  <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
                </div>
              </div>

              <div>
                <label for="selectBadgets" class="form-label"
                  >ميزانية المشروع
                  <sup>*</sup>
                </label>
                <select
                  id="selectBadgets"
                  class="form-select"
                  :class="{
                    error: !selectBadgetsIsValid && selectBadgetsFoucsed,
                  }"
                  v-model="form.selectBadgets"
                >
                  <option value="0" selected disabled>
                    اختار المناسب لك<span>&#8595;</span>
                  </option>
                  <option value="1000 to 5000">من 1000 الى 5000$</option>
                  <option value="5000 to 10,000">من 5000 الى 10,000$</option>
                  <option value="10,000 to 20,000">
                    من 10,000 الى 20,000$
                  </option>
                  <option value="20,000 to 50,000">
                    من 20,000 الى 50,000$
                  </option>
                  <option value="+50,000">فوق ال50,000$</option>
                </select>
                <div
                  v-if="!selectBadgetsIsValid && selectBadgetsFoucsed"
                  class="error d-flex align-items-center justify-content-between mb-3"
                >
                  <p class="mb-0">يرجى اختيار ميزانية</p>
                  <font-awesome-icon icon="fa-solid fa-circle-exclamation" />
                </div>
              </div>

              <button
                type="button"
                @click="submitForm"
                class="btn btn-primary submit_btn d-flex align-items-center justify-content-center w-100"
              >
                {{ textBtn }}
                <span v-if="isProgress" class="dot-elastic d-block"></span>
              </button>
              <h6 class="text-grey text-center">
                .سنقوم باستقبال رسالتك والرد عليه في اقرب وقت
              </h6>
            </div>
          </form>
        </div>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
// import ContactForm from './ContactForm.vue';
export default {
  props: ["open"],
  emits: ["close"],
  data() {
    return {
      textBtn: "ارسال",
      form: {
        name: "",
        phone: "",
        selectBadgets: "",
      },
      results: "",
      nameFoucsed: false,
      phoneFoucsed: false,
      selectBadgetsFoucsed: false,
      isAnimate: false,
      isProgress: false,
      animate: false,
    };
  },
  computed: {
    nameIsValid() {
      return !!this.form.name;
    },
    phoneIsValid() {
      return !!this.form.phone;
    },
    selectBadgetsIsValid() {
      return !!this.form.selectBadgets;
    },
    formIsValid() {
      return this.nameIsValid && this.phoneIsValid && this.selectBadgetsIsValid;
    },
  },
  methods: {
    async submitForm() {
      //   const submit = document.querySelector("#submit");
      if (this.formIsValid) {
        this.textBtn = "جاري الارسال";
        this.isProgress = true;

        const url = "/api/sentMail.php";
        await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.form.name,
            phone: this.form.phone,
            selectBadgets: this.form.selectBadgets,
          }),
        })
          .then((response) => {
            console.info(response);
            this.isProgress = false;
            this.isAnimate = true;
            this.textBtn = "تم الإرسال";
            setTimeout(() => {
              this.$emit("close");
              this.$router.push("/contact-success");
            }, 2100);
          })
          .catch((error) => {
            this.textBtn = "لم يتم الإرسال, الرجاء معاودة المحاولة لاحقاً.";
            console.error(error);
          });
      } else {
        this.isAnimate = false;
        this.nameFoucsed = true;
        this.phoneFoucsed = true;
        this.selectBadgetsFoucsed = true;
      }
    },
    preventClose() {
      this.animate = true;
      setTimeout(() => {
        this.animate = false;
      }, 500);
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}

dialog {
  position: fixed;
  top: 5vh;
  width: 85vw;
  max-width: 445px;
  max-height: 92vh;
  z-index: 9999;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgb(0 0 0 / 26%);
  padding: 0;
  margin: auto;
  overflow: auto;
  background-color: white;
}

.auth-dialog-enter-from,
.auth-dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.auth-dialog-enter-active {
  transition: all 0.3s ease-out;
}

.auth-dialog-leave-active {
  transition: all 0.3s ease-in;
}

.auth-dialog-enter-to,
.auth-dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

.authContainer {
  padding: 1rem;
}

@media (min-width: 768px) {
  dialog[data-v-3690d7d8] {
    left: calc(50% - 16rem);
    width: 32rem;
  }

  .authContainer {
    padding: 3rem;
  }
}

.preventClose {
  animation: animateHeart 0.3s ease-out;
}

@keyframes animateHeart {
  0% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

@media (max-width: 540px) {
  h5 {
    font-size: 16px;
  }
}

.btn-close {
  position: absolute;
  top: 20px;
  left: 20px;
}

img {
  width: 100px;
}

/* Start Form */
label {
  display: block;
  margin-bottom: 8px;
  color: #585757;
}

sup {
  font-size: 13px;
  color: #e92c2c;
}

input[type="text"],
select {
  height: 48px;
  padding: 0 12px;
  display: block;
  width: 100%;
  outline: none;
  background: #ffffff;
  color: #585757;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  transition: 0.2s ease-in-out;
}

input[type="text"]::placeholder,
select option {
  color: #969696;
}

input[type="text"]:focus,
select:focus {
  border: 1px solid #0085ff;
  box-shadow: 0px 0px 2px 2px rgba(0, 133, 255, 0.15);
}

.error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--error-color);
  font-weight: bold;
}

.error p,
.error svg {
  color: var(--error-color);
  font-weight: bold;
}

input.error,
select.error,
.m-phone-number-input.error {
  border: 1px solid var(--error-color) !important;
  box-shadow: none !important;
  border-radius: 8px;
}

/*  Submit Btn */
.submit_btn {
  background-color: var(--primary-color);
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.submit_btn:active {
  background-color: var(--primary-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.submit_btn:focus {
  background-color: var(--primary-color);
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.submit_btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0px;
  height: 0px;
  background-color: rgba(255, 255, 255, 0.15);
  z-index: -1;
  color: #fff;
}

.submit_btn:hover {
  background-color: var(--primary-color);
}

.submit_btn:hover::before {
  width: 200%;
  height: 200%;
  -webkit-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
}

.dot-elastic {
  position: relative;
  margin-right: 30px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  -webkit-animation: dotFlashing 1s infinite linear alternate;
  animation: dotFlashing 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  -webkit-animation: dotFlashing 1s infinite alternate;
  animation: dotFlashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
  -webkit-animation: dotFlashing 1s infinite alternate;
  animation: dotFlashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes dotFlashing {
  0% {
    background-color: rgba(0, 0, 0, 0.5);
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: rgba(0, 0, 0, 0.5);
  }

  50%,
  100% {
    background-color: #ebe6ff;
  }
}

/* End Form */
</style>
