<template>
  <section class="pb-5">
    <base-banner mode="grey">اعمالنا</base-banner>
    <div class="container">
      <div class="row">
        <!-- Start portfolio -->
        <portfolio-cover
          v-for="(portfolio, index) in showPortfolio"
          :key="portfolio.id"
          :id="portfolio.id"
          :cover="portfolio.cover"
          :title="portfolio.title"
          :description="portfolio.description"
          :name="portfolio.name"
          :details="portfolio.details"
          :specifications="portfolio.specifications"
          :type="portfolio.type"
          :date="portfolio.date"
          :trade="portfolio.trade"
          :images="portfolio.images"
          :data-aos="evenOdd(index)"
        ></portfolio-cover>
        <!-- End portfolio -->
      </div>
    </div>
  </section>
</template>

<script>
import PortfolioCover from "../components/portfolio/PortfolioCover.vue";
export default {
  components: { PortfolioCover },
  computed: {
    showPortfolio() {
      return this.$store.getters["portfolios"];
    },
  },
  methods: {
    evenOdd(index) {
      let animation = "";
      if (index % 2 == 0) {
        animation = "flip-right";
      } else animation = "flip-left";
      return animation;
    },
  },
};
</script>
