<template>
  <section>
    <landing-carousel></landing-carousel>
    <the-about></the-about>
    <the-portfolio></the-portfolio>
    <the-advantages></the-advantages>
    <the-accordion></the-accordion>
    <the-consultation></the-consultation>
    <the-blogs></the-blogs>
    <the-work></the-work>
    <!-- <the-testimonials></the-testimonials> -->
    <the-experts></the-experts>
    <the-technologies></the-technologies>

    <the-partners></the-partners>
    <!-- <the-teams></the-teams> -->
    <the-contact></the-contact>
    <the-strategy></the-strategy>
  </section>
</template>

<script>
import LandingCarousel from "@/components/home/LandingCarousel.vue";
import TheAbout from "@/components/home/TheAbout.vue";
import ThePortfolio from "@/components/home/ThePortfolio.vue";
import TheAdvantages from "@/components/home/TheAdvantages.vue";
import TheAccordion from "@/components/home/TheAccordion.vue";
import TheWork from "@/components/home/TheWork.vue";
import TheBlogs from "@/components/home/TheBlogs";
// import TheTestimonials from '@/components/home/TheTestimonials.vue';
import TheExperts from "@/components/home/TheExperts.vue";
import TheTechnologies from "@/components/home/TheTechnologies.vue";
import TheConsultation from "@/components/home/TheConsultation.vue";
import ThePartners from "@/components/home/ThePartners.vue";
// import TheTeams from '@/components/home/TheTeams.vue';
import TheContact from "@/components/home/TheContact";
import TheStrategy from "@/components/home/TheStrategy.vue";
export default {
  components: {
    LandingCarousel,
    TheAbout,
    ThePortfolio,
    TheAdvantages,
    TheAccordion,
    TheWork,
    TheBlogs,
    // TheTestimonials,
    TheExperts,
    TheTechnologies,
    TheConsultation,
    ThePartners,
    // TheTeams,
    TheContact,
    TheStrategy,
  },
};
</script>
