<template>
  <div class="col-12 col-lg-6 mb-3">
    <router-link :to="'/portfolio/' + id" class="w-100 h-100">
      <div class="box">
        <div class="image position-relative">
          <img :srcset="cover" alt="" class="img-fluid" />
          <div class="inner-text position-absolute">
            <div class="text">
              <h4 class="text-header fw-bold">{{ title }}</h4>
              <p class="text-parag text-grey">{{ description }}</p>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ["id", "cover", "title", "description"],
};
</script>

<style scoped>
.inner-text {
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border: 10px solid transparent;
  background-color: #fff;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.text {
  padding-right: 20px;
  -webkit-transition: 0.7s ease;
  transition: 0.7s ease;
}

.text p {
  font-size: 14px;
  font-weight: 400;
}

.box:hover .inner-text {
  opacity: 1;
}

.box:hover .text {
  margin-bottom: 10px;
}
</style>
