<template>
    <section class="partners-icons">
        <!-- Start Section Title -->
        <base-title>شركاؤنا</base-title>
        <!-- End Section Title -->

        <div class="container">
            <!-- Icons -->
            <div class="icon-image" v-for="(icon , index) in icons" :key="icon" :data-aos="evenOdd(index)"
            :data-aos-delay="400 + index*10"
                data-aos-easing="ease">
                <img :srcset="icon" :alt="index" class="w-100 h-100">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            icons: [
                '/images/partners/1.png',
                '/images/partners/2.png',
                '/images/partners/3.png',
                '/images/partners/4.png',
                '/images/partners/5.png',
                '/images/partners/6.png',
                '/images/partners/7.png',
                '/images/partners/8.png',
                '/images/partners/9.png',
                '/images/partners/10.png',
                '/images/partners/11.png',
                '/images/partners/12.png',
                '/images/partners/13.png',
                '/images/partners/14.png',
                '/images/partners/15.png',
                '/images/partners/16.png',
                '/images/partners/17.png',
                '/images/partners/18.png',
                '/images/partners/19.png',
                '/images/partners/20.png',
                '/images/partners/21.png',
                '/images/partners/22.png',
            ]
        }
    },
    methods: {
        evenOdd(index) {
            let animation = "";
            if (index % 2 == 0) {
                animation = "flip-right";
            } else animation = "flip-left";
            return animation;
        },
    },
}
</script>

<style scoped>
/* Start Partners Icons */
.partners-icons {
    padding-top: 60px;
    padding-bottom: 60px;
}

.partners-icons .container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 40px;
}

.partners-icons .icon-image {
    width: 100px;
    height: 100px;
    margin: auto;
    cursor: pointer;
}

.partners-icons .icon-image img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: 0.3s ease;
    transition: filter 0.3s ease;
}

.partners-icons .icon-image img:hover {
    -webkit-filter: none;
    filter: none;
}

.partners-icons .icon-image:nth-child(21) img {
    -webkit-filter: brightness(0);
    filter: brightness(0);
}

.partners-icons .icon-image:nth-child(21):hover img {
    -webkit-filter: contrast(0.8);
    filter: contrast(0.8);
}

.partners-icons .icon-image:nth-child(22) img {
    -webkit-filter: opacity(0.5);
    filter: opacity(0.5);
}

.partners-icons .icon-image:nth-child(22):hover img {
    -webkit-filter: opacity(1);
    filter: opacity(1);
}

@media (max-width: 768px) {
    .partners-icons .container {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}

/* End Partners */
</style>