<template>
    <!-- Start Section Title -->
    <div class="title" data-aos="fade-down" data-aos-easing="ease" data-aos-delay="500" :class="[margin , color]">
        <h1 class="my-0">
            <span class="line">
                <span class="label">
                    <slot></slot>
                </span>
            </span>
        </h1>
    </div>
    <!-- End Section title -->
</template>

<script>
export default {
    props: {
        margin: {
            type: String,
            required: false,
            default: 'mb-large'
        },
        color: {
            type: String,
            required: false,
            default: 'primary'
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap');

/* Start Section Title */
.title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 87.2px;
    z-index: 99;
    background-color: transparent;
}

.mb-small {
    margin-bottom: 20px;
}

.mb-large {
    margin-bottom: 80px;
}

.title h1 {
    position: relative;
    font-family: "Cairo", sans-serif;
    line-height: 1.9;
}

.title h1:before {
    content: "";
    position: absolute;
    top: -5px;
    right: 0px;
    width: 100%;
    height: 2px;
    z-index: 1;
    background-color: var(--primary-color);
    transition: width 0.1s linear, height 0.1s linear;
    -webkit-transition: width 0.1s linear, height 0.1s linear;
    transition-delay: 0.3s;
    -webkit-transition-delay: 0.3s;
}

.title h1:after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0px;
    width: 100%;
    height: 2px;
    z-index: 1;
    background-color: var(--primary-color);
    transition: width 0.1s linear, height 0.1s linear;
    -webkit-transition: width 0.1s linear, height 0.1s linear;
    transition-delay: 0.1s;
    -webkit-transition-delay: 0.1s;
}

.title h1:hover:before {
    width: 0;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
}

.title h1:hover:after {
    width: 0;
    transition-delay: 0.2s;
    -webkit-transition-delay: 0.2s;
}

.title h1:hover .line {
    border-color: #000;
}

.title h1:hover .line:before {
    height: 0;
    transition-delay: 0.3s;
    -webkit-transition-delay: 0.3s;
}

.title h1:hover .line:after {
    height: 0;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.title h1:hover .label {
    color: #000;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    -webkit-transition: color 0.1s;
    transition: color 0.1s;
}

.title h1 .line {
    position: relative;
    padding: 4px 8px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px solid transparent;
    transform-style: flat;
    -webkit-transform-style: flat;
    transition: color 10ms ease-in-out 0.1s;
    -webkit-transition: color 10ms ease-in-out 0.1s;
}

.title h1 .line .label {
    font-weight: 300;
    transition-delay: 0.15s;
    -webkit-transition-delay: 0.15s;
    transition: color 0.1s;
    -webkit-transition: color 0.1s;
}

.title h1 .line:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -2px;
    width: 2px;
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.1s linear, height 0.1s linear;
    transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -webkit-transition: width 0.1s linear, height 0.1s linear;
}

.title h1 .line:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: -2px;
    width: 2px;
    height: 100%;
    background-color: var(--primary-color);
    transition: width 0.1s linear, height 0.1s linear;
    transition-delay: 0.2s;
    -webkit-transition: width 0.1s linear, height 0.1s linear;
    -webkit-transition-delay: 0.2s;
}

.title.white h1 {
    color: #fff;
}

.title.white h1::before,
.title.white h1::after {
    background-color: #fff;
}

.title.white h1 .line:before,
.title.white h1 .line:after {
    background-color: #fff;
}

/* End Section Title */
</style>