<template>
    <div v-if="!animate" class="special-head">
        <h4>
            <slot></slot>
        </h4>
    </div>

    <div v-else class="special-head">
        <h4 data-aos="fade-up" data-aos-easing="ease" data-aos-delay="200" :class="color">
            <slot></slot>
        </h4>
    </div>

</template>

<script>
export default {
    props: {
        animate: {
            type: Boolean,
            required: false,
            default: false
        },
        color: {
            type: String,
            required: false,
            default: 'primary'
        }
    },
}
</script>

<style scoped>
.special-head h4 {
    position: relative;
    font-weight: 200;
    margin-right: 45px;
    color: #000;

}

.special-head h4::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -40px;
    width: 30px;
    height: 2px;
    background-color: var(--primary-color);
}
.special-head h4.white{
    color: #fff;
}
.special-head h4.white::before {
    background-color: #fff;

}
</style>