<template>
    <div class="text">
        <p data-aos="fade-up">
            تطبيقات الويب الحديثة لديها توقعات مستخدم أكبر ومتطلبات أعلى من أي وقت مضى. يجب أن تكون
            تطبيقات الويب الحالية متاحة على مدار الساعة طوال أيام الأسبوع من أي مكان في جميع أنحاء
            العالم ويمكن استخدامها عمليًا من أي جهاز أو حجم شاشة.
            يجب أن تكون تطبيقات الويب الحديثة آمنة وقابلة للتكيف وقابلة للتطوير لتلبية الزيادة
            المستقبلية في الطلب. مع وضع هذه الصورة في الاعتبار ، قمنا بإنشاء قائمة من 4 مبادئ بارزة
            لحلول تطوير تطبيقات الويب الحديثة.
            تبدأ الرحلة إلى تطوير الويب الحديث بفهم مبادئ تطوير الويب الحديثة
        </p>

        <h5 class="fw-bold mt-3" data-aos="fade-up">
            لذلك دعونا نبدأ!
        </h5>


        <h3 class="fw-bold my-3" data-aos="fade-up">
            المستخدم أولا
        </h3>

        <p data-aos="fade-up">
            عندما يتعلق الأمر بالتركيز على تجربة المستخدم ، فإن أحد الأسئلة الشائعة التي يتم تجاهلها
            غالبًا هو من هو المستخدم النهائي؟ سيساعدك العثور على إجابة هذا السؤال على فهم الهدف من إنشاء
            تطبيق الويب المحدد.
            بدلاً من النظر إليه كتطبيق ، ستبدأ في تطوير الويب كحل لمشكلة واحدة محددة. بمجرد معرفة الغرض
            ، ابدأ العمل من أجل تجربة مستخدم جميلة . نظرًا لأنه في نهاية اليوم ، فإن تجربة المستخدم هي
            التي تحافظ على التزام أي عميل بعلامة تجارية أو منتج.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            الأمان ليس ميزة إضافية
        </h3>

        <p data-aos="fade-up">
            تعاني معظم تطبيقات الويب من نقاط ضعف شديدة الخطورة مثل البرمجة النصية عبر المواقع وحقن SQL
            وغيرها. هذه الثغرات ليست تهديدات جديدة. بدلاً من ذلك ، تم تسجيلها كخطر أمني كبير لأكثر من
            عشر سنوات متتالية. لأي سبب يحدث هذا؟ على الرغم من أن الأسباب قد تختلف ، إلا أن إحدى المشكلات
            الرئيسية هي أن المطورين غالبًا ما يتبنون نهجًا رافضًا للأمان. إنهم يعاملونها كمكون يمكنهم
            إضافته في النهاية. الآن أكثر من أي وقت مضى ، يجب دمج الأمان في تطبيقات الويب الخاصة بك منذ
            البداية.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            التغيير هو عملية لا تنتهي
        </h3>

        <p data-aos="fade-up">
            التكنولوجيا تتطور باستمرار. كما يعلم المطورون ، هذه نعمة ونقمة.
            من ناحية أخرى ، يتقدم تطوير تطبيقات الويب بشكل مستمر ، حيث يتم تقديم مكتبات جديدة وأدوات
            تطوير تطبيقات الويب وأطر العمل بشكل متكرر. تؤدي هذه الابتكارات إلى تحسين ميزات التطبيق
            والمزيد من التطوير الفعال.
            بعبارة أخرى ، تطبيقات الويب الحديثة ليست "كاملة" أبدًا. لا يمكنك نشر تطبيق ثم إدارة ظهرك
            عليه. يتعين على مطوري الويب الحديثين تحديث تطبيقات الويب الخاصة بهم بانتظام لضبطها وفقًا
            للاتجاهات المتغيرة ومعايير الأمان.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            فصل الوحدات

        </h3>

        <p data-aos="fade-up">
            اتبع المطورون السابقون نهجًا مترابطًا لتطوير تطبيقات الويب ، حيث قاموا ببناء كل ميزة مباشرة
            في تطبيقاتهم. المشكلة الأساسية في هذا الأسلوب هي الصيانة. نتيجة لذلك ، قد يؤدي التغيير في أي
            وحدة نمطية إلى مشاكل في قطاعات أخرى ، مما يجعل التطبيقات غير قابلة للإدارة.
            ومع ذلك ، تتطلب هندسة تطوير تطبيقات الويب الحديثة في الوقت الحاضر فصل الاهتمامات.
            هذه ليست سوى 4 مبادئ لتطوير تطبيقات الويب الحديثة ، ولكن القائمة يمكن أن تكون أطول.
        </p>

        <h5 class="fw-bold mt-5" data-aos="fade-up">
            <i class="fas fa-pencil-alt me-2"></i>أبرز اقوال <span class="author">"
                مارك زوكربيرج"</span>

        </h5>
        <blockquote data-aos="fade-down">
            أفعل كل شيء على هاتفي كالكثير من الناس
        </blockquote>
    </div>
</template>