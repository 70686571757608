<template>
  <section class="portfolio">
    <div class="container">
      <!-- Start Row 1 -->
      <div class="row">
        <!-- Start portfolio -->
        <portfolio-cover
          v-for="(portfolio, index) in showPortfolio"
          :key="portfolio.id"
          :id="portfolio.id"
          :cover="portfolio.cover"
          :title="portfolio.title"
          :description="portfolio.description"
          :name="portfolio.name"
          :details="portfolio.details"
          :specifications="portfolio.specifications"
          :type="portfolio.type"
          :date="portfolio.date"
          :trade="portfolio.trade"
          :images="portfolio.images"
          :data-aos="evenOdd(index)"
        ></portfolio-cover>
        <!-- End portfolio -->
      </div>
      <!-- start view more Button -->
      <base-more-button to="/portfolios"></base-more-button>
      <!-- End view more Button -->
    </div>
  </section>
</template>

<script>
import PortfolioCover from "../portfolio/PortfolioCover.vue";
export default {
  components: { PortfolioCover },
  computed: {
    showPortfolio() {
      const homePortfolio = this.$store.getters["portfolios"];
      return homePortfolio.slice(0, 4);
    },
  },
  methods: {
    evenOdd(index) {
      let animation = "";
      if (index % 2 == 0) {
        animation = "flip-right";
      } else animation = "flip-left";
      return animation;
    },
  },
};
</script>

<style scoped>
.portfolio {
  padding: 60px 0;
}
</style>
