<template>
    <div class="text">

        <h3 class="fw-bold my-3" data-aos="fade-up">
            استضافة ويندوز
        </h3>
        <p data-aos="fade-up">
            IIS وقواعد بيانات أكسس وخوادم ، ASP.net هى استضافة تعمل بنظام تشغيل ويندوز سيرفر و تدعم لغة
            برمجة
            وعادةَ ما تكون أعلى في السعر وينتشر استخدامها في المواقع الحكومية والشركات ويعد انتشارها أقل
            وسعرها أعلي مقارنةً باستضافة لينكس
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            إستضافة لينكس
        </h3>

        <p data-aos="fade-up">
            وتعد الاستضافة الأكثر انتشاراً والتي تستخدمها أغلبية المواقع ومن بينها المواقع الكبري كفيس
            بوك وتويتر وغيرها بالاضافة إلى مليارات المواقع الصغيرة.
            MySQLI والنسخة المًطورة منها MySQL وقواعد بيانات php تدعم استضافة لينكس لغة برمجة
            وتعتبر استضافة لينكس الاستضافة الأقل سعراً على الاطلاق
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            سرعة اتصال الخادم بالانترنت
        </h3>

        <p data-aos="fade-up">
            تؤثر سرعة اتصال الخادم بالانترنت على سرعة نقل البيانات بين المستخدم والاستضافة والعكس مما
            يؤثر بدوره على سرعة موقع الويب بصورة مباشرة ويظهر ذلك بصورة واضحة جدًا إذا كانت سرعة اتصال
            المستخدم أعلى من سرعة اتصال السيرفر وفي أوقات زيادة عدد زوار موقعك أيضًا.
        </p>

        <h3 class="fw-bold my-3" data-aos="fade-up">
            الدعم الفنى
        </h3>

        <p data-aos="fade-up">
            يعتبر الدعم الفنى نقطة مهمة جدًا في مجال الاستضافة وهي من أهم العوامل التي تُفرّق بين
            الاستضافات القوية والاستضافات الضعيفة أو الموزعين فبالتأكيد ستحتاج الى مساعدة الدعم الفني في
            أحد الأيام، فما يفرق بين الاستضافة الجيدة وغيرها هو هل ستجد الدعم الفني في هذا الوقت؟ وكيف
            سيكون الرد؟ وكم ستطول مدة الرد؟ وهل هو متاح 24 ساعة 7 ايام اسبوعياً ام ماذا ، وهل هو دعم
            لايف “مباشر” أم عن طريق التذاكر. أيضًا هل فريق الدعم لدية الخبرة الكافية لكي يحل المشكلة في
            وقت قصير ام ماذا؟
        </p>

        <h5 class="fw-bold mt-5" data-aos="fade-up">
            <i class="fas fa-pencil-alt me-2"></i>أبرز اقوال
            <span class="author">
                "بول كوكسن"
            </span>

        </h5>
        <blockquote data-aos="fade-down">
            موقعك يسوق لك 24 ساعة في اليوم، 7 أيام في الأسبوع. لا يوجد موظف يمكنه فعل ذلك
        </blockquote>
    </div>
</template>