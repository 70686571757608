<template>
  <footer>
    <!--Remove padding first footer -->
    <div class="container">
      <div class="row first-footer">
        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="details">
            <div
              class="logo mb-3"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="400"
            >
              <router-link to="/home">
                <img src="/images/logo.png" alt="mootawer-logo"
              /></router-link>
            </div>
            <div
              class="text"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="600"
            >
              <p class="mb-5 mb-md-3">
                مطور هي وكالة تطوير تطبيقات ومواقع إلكترونية تفخر بعملها الجاد
                وإبداعها.
              </p>
            </div>
            <div class="contact">
              <p
                class="location"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="800"
              >
                <i class="fas fa-map-marker-alt"></i>
                لبنان - طرابلس - الضم والفرز - بناية ريمال - بلوك أ - طابق أرضي
              </p>
              <!-- <p
                class="location"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="800"
              >
                <i class="fas fa-map-marker-alt"></i>
                بريطانيا - لندن - حديقة كوفنت - شارع شيلتون 75-71
              </p> -->
              <a
                href="tel:0096181230256"
                class="phone-link d-block mb-3"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 82 82"
                  style="enable-background: new 0 0 82 82"
                  xml:space="preserve"
                >
                  <path
                    d="M64.5,78.2c1.7-1.9,3.6-3.6,5.4-5.4c2.6-2.7,2.7-5.9,0-8.6c-3.1-3.2-6.3-6.3-9.4-9.4
							c-2.6-2.6-5.8-2.6-8.4,0c-2,1.9-3.9,3.9-5.9,5.9c-0.1,0.1-0.3,0.2-0.4,0.3l-1.3,1.3c-0.4,0.2-0.7,0.2-1.2,0
							c-1.3-0.7-2.6-1.2-3.8-2c-5.7-3.6-10.5-8.2-14.7-13.4c-2.1-2.6-4-5.3-5.3-8.4c-0.2-0.5-0.2-0.9,0.1-1.3l1.3-1.3
							c0.1-0.1,0.1-0.2,0.2-0.3c0.6-0.6,1.2-1.1,1.8-1.7c1.4-1.3,2.7-2.7,4.1-4.1c2.7-2.7,2.7-5.9,0-8.6c-1.5-1.5-3.1-3.1-4.6-4.6
							c-1.6-1.6-3.2-3.2-4.8-4.8c-2.6-2.5-5.8-2.5-8.4,0c-2,1.9-3.9,3.9-5.9,5.9c-1.9,1.8-2.8,3.9-3,6.5c-0.3,4.1,0.7,8,2.1,11.8
							C5.2,43.8,9.6,50.7,15,57.1c7.2,8.6,15.9,15.4,26,20.4c4.6,2.2,9.3,3.9,14.4,4.2C58.9,81.8,62,81,64.5,78.2z"
                  ></path>
                  <path
                    d="M41.1,15.7c-0.7,0-1.5,0.1-2.2,0.4c-1.7,0.8-2.5,2.8-2,4.8c0.4,1.8,2,3,3.9,3c4.6,0.1,8.6,1.5,12,4.6c3.7,3.4,5.4,7.7,5.6,12.8c0,0.9,0.4,1.9,0.9,2.6c1.1,1.5,3,1.9,4.8,1.2c1.6-0.6,2.5-2,2.5-3.9c-0.1-7-2.6-12.9-7.5-18.1C54.1,18.4,48.1,15.8,41.1,15.7z"
                  ></path>
                  <path
                    d="M69,11.4c8.5,8.7,12.5,18.1,12.8,29.1c0.1,2.5-1.5,4.2-3.9,4.3c-2.6,0.1-4.3-1.4-4.4-4c-0.1-5.4-1.4-10.5-4-15.2C63.5,14.9,54.2,9.3,42,8.6c-1.4-0.1-2.6-0.2-3.6-1.3c-1.2-1.4-1.3-3-0.7-4.6c0.7-1.6,2-2.4,3.8-2.4c8,0.1,15.3,2.4,22,6.8C65.7,8.6,67.8,10.4,69,11.4z"
                  ></path>
                </svg>
                <bdi>00961 81 230 256</bdi>
              </a>
              <a
                href="mailto:info@mootawer.com"
                class="location d-block mb-3"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="800"
              >
                <font-awesome-icon
                  class="text-primary me-2"
                  icon="fa-solid fa-envelope"
                />
                info@mootawer.com
              </a>
            </div>
          </div>
        </div>

        <!-- <div class="col-xl-3 col-lg-6 col-md-6 ">
                <div class="all-links">
                    <div class="logo" data-aos="fade-down" data-aos-easing="ease" data-aos-delay="400">
                        <h3 class="head-link ">
                            روابط مفيدة

                        </h3>
                    </div>
                    <div class="links">
                        <ul>
                            <li data-aos="fade-down" data-aos-easing="ease" data-aos-delay="600">
                                جذابة وهادفة
                            </li>
                            <li data-aos="fade-down" data-aos-easing="ease" data-aos-delay="700">
                                أحداث الحياة الخارجية
                            </li>
                            <li data-aos="fade-down" data-aos-easing="ease" data-aos-delay="800">
                                صمم حدثك المثالي
                            </li>
                            <li data-aos="fade-down" data-aos-easing="ease" data-aos-delay="900">
                                اربط عوالمك
                            </li>
                            <li data-aos="fade-down" data-aos-easing="ease" data-aos-delay="1000">
                                تحسين إنتاجية مكان العمل
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="col-xl-3 col-lg-6 col-md-6 ">
                <div class="insta">
                    <div class="logo" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                        <h3 class="insta-head">
                            انستقرام
                        </h3>
                    </div>
                    <div class="images d-flex flex-wrap justify-content-around align-items-center">
                        <a href="#" data-aos="flip-left" data-aos-easing="ease" data-aos-delay="600">
                            <div class="img">
                                <img src="assets/images/insta1.jpg" alt="" class="w-100 h-100">
                            </div>
                        </a>
                        <a href="#" data-aos="flip-right" data-aos-easing="ease" data-aos-delay="700">
                            <div class="img">
                                <img src="assets/images/insta2.jpg" alt="" class="w-100 h-100">
                            </div>
                        </a>
                        <a href="#" data-aos="flip-left" data-aos-easing="ease" data-aos-delay="800">
                            <div class="img">
                                <img src="assets/images/insta3.jpg" alt="" class="w-100 h-100">
                            </div>
                        </a>
                        <a href="#" data-aos="flip-right" data-aos-easing="ease" data-aos-delay="900">
                            <div class="img">
                                <img src="assets/images/insta4.jpg" alt="" class="w-100 h-100">
                            </div>
                        </a>
                        <a href="#" data-aos="flip-left" data-aos-easing="ease" data-aos-delay="1000">
                            <div class="img">
                                <img src="assets/images/insta5.jpg" alt="" class="w-100 h-100">
                            </div>
                        </a>
                        <a href="#" data-aos="flip-right" data-aos-easing="ease" data-aos-delay="1100">
                            <div class="img">
                                <img src="assets/images/insta6.jpg" alt="" class="w-100 h-100">
                            </div>
                        </a>
                    </div>
                </div>

            </div> -->

        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="letter">
            <div
              class="logo"
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-delay="400"
            >
              <h3 class="mx-auto me-lg-auto ms-lg-0">انضم الينا</h3>
            </div>
            <div class="text">
              <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                إذا كنت ترغب في الانضمام إلى فريقنا ، أرسل إلينا بريدًا
                إلكترونيًا مع سيرتك الذاتية بالأسفل.
              </p>
            </div>
            <!-- <form action="" method="post" enctype="text/plain">
                        <div class="input-group mb-3">
                            <input type="email" class="form-control" placeholder="البريد الالكتروني"
                                aria-label="Example text with button addon" aria-describedby="button-addon1"
                                data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="800">
                            <button class="btn btn-outline-secondary" type="submit" id="button-addon1"
                                data-aos="zoom-in" data-aos-easing="ease" data-aos-delay="1000">ارسال
                                <i class="fas fa-long-arrow-alt-left"></i>
                            </button>
                        </div>
                    </form> -->

            <div>
              <a
                href="mailto:info@mootawer.com"
                class="mx-auto me-lg-0 ms-lg-auto text-primary"
                data-aos="fade-up"
                data-aos-easing="ease"
                data-aos-delay="700"
                style="width: fit-content"
                >info@mootawer.com</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="second-footer d-flex justify-content-between align-items-center"
      >
        <div class="copy">
          جميع الحقوق محفوظة @ حقوق النشر <span id="footerYear"></span> مملوكة
          لشركة مطور
        </div>
        <div class="sociol">
          <ul class="social-links d-flex align-items-center gap-3 px-0 mb-0">
            <li v-for="social in socialMedia" :key="social.id">
              <a :href="social.link">
                <font-awesome-icon :icon="social.icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    socialMedia() {
      return this.$store.getters["social"];
    },
  },
};
</script>

<style scoped>
@-webkit-keyframes moveArrow {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40%,
  60% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes moveArrow {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  40%,
  60% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

footer {
  padding-top: 60px;
  background-color: rgba(0, 0, 0, 0.87);
  color: #fff;
}

footer .logo {
  height: 45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

footer .logo .head-link {
  margin-right: 30px;
}

footer .logo h3 {
  font-size: 19px;
  line-height: 1.23em;
  font-weight: 700;
}

footer .details .text p {
  margin-bottom: 20px;
  text-align: justify;
  line-height: 1.6;
}

footer .details .contact a {
  width: fit-content;
  color: #fff !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease !important;
}

footer .details .contact a:hover {
  color: var(--primary-color) !important;
}

footer .details .contact .phone-link svg {
  width: 18px;
  height: 18px;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  margin-left: 10px;
}

footer .details .contact .phone-link svg path {
  fill: var(--primary-color);
}

footer .details .contact .phone-link svg path:nth-child(2) {
  -webkit-animation: header-phone 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
  animation: header-phone 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
}

footer .details .contact .phone-link svg path:nth-child(3) {
  -webkit-animation: header-phone2 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
  animation: header-phone2 1s infinite cubic-bezier(0.1, 0.57, 0.5, 1);
}

@-webkit-keyframes header-phone {
  0%,
  30% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }

  80% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
  }
}

@keyframes header-phone {
  0%,
  30% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }

  80% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes header-phone2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }

  70%,
  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
  }
}

@keyframes header-phone2 {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
  }

  70%,
  90% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 0;
  }
}

footer .location {
  font-size: 18px;
  color: #fff;
}

@media (max-width: 540px) {
  footer .location {
    font-size: 15px;
  }
}

footer .details .contact .location i {
  color: var(--primary-color);
  margin-left: 10px;
  width: 18px;
}

footer .details .contact .location:not(:last-child) i {
  -webkit-animation: updown 1s ease 0.2s infinite;
  animation: updown 1s ease 0.2s infinite;
}

@-webkit-keyframes updown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes updown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

footer .links ul li {
  margin-bottom: 15px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

footer .links ul li:hover {
  color: var(--primary-color);
}

footer .images .img {
  width: 77px;
  height: 77px;
  margin-bottom: 10px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  position: relative;
}

footer .images .img:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
  border: none !important;
}

footer .images .img:hover::before {
  opacity: 1;
}

footer .images .img::before {
  font-family: "Font Awesome 5 Brands";
  content: "\f16d";
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 18px;
  z-index: 10;
  opacity: 0;
  -webkit-transition: 0.9s ease;
  transition: 0.9s ease;
}

footer .letter p {
  line-height: 1.8;
  text-align: justify;
  margin-bottom: 30px;
}

footer .letter a {
  color: grey;
  display: block;
  -webkit-transition: 0.5 ease;
  transition: 0.5 ease;
}

footer .letter a:hover {
  color: var(--primary-color);
}

footer form input {
  background-color: transparent !important;
  text-align: right !important;
  border-style: solid !important;
  border-color: #fff #fff #fff transparent !important;
  border-width: 1px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #fff !important;
  font-family: "Cairo", sans-serif;
}

footer form .btn {
  border-style: solid !important;
  border-color: #fff transparent #fff #fff !important;
  border-width: 1px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

footer form .btn:hover {
  background-color: transparent;
  color: var(--primary-color);
}

footer form .btn:hover i {
  -webkit-animation: moveArrow 1.5s infinite;
  animation: moveArrow 1.5s infinite;
}

footer form .btn i {
  margin: 0 10px 0 20px;
}

footer .second-footer {
  border-top: 1px solid #252525;
  padding: 30px 0;
}

footer .second-footer a {
  color: #fff !important;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

footer .second-footer a:hover {
  color: var(--primary-color) !important;
}

@media (min-width: 992px) and (max-width: 1024px) {
  footer .insta {
    margin-top: 50px;
  }

  footer .letter {
    margin-top: 50px;
  }
}

@media (max-width: 912px) {
  footer .logo {
    margin-bottom: 10px;
  }

  footer .details {
    width: 100%;
    margin: 0 auto 50px;
  }

  footer .letter {
    width: 100%;
    margin: 0 auto 50px;
  }

  footer .all-links {
    width: 100%;
    margin: 0 auto 50px;
  }

  footer .images {
    margin-bottom: 50px;
  }

  footer .images .img {
    width: 88px !important;
    height: 88px !important;
  }

  footer .insta-head {
    margin-right: 30px;
  }
}

@media (max-width: 480px) {
  footer .images .img {
    width: 145px !important;
    height: 145px !important;
  }

  footer .first-footer {
    padding-bottom: 0px;
  }

  footer .second-footer {
    width: 100%;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  footer .second-footer .copy {
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (min-width: 1367px) {
  footer .images .img {
    width: 94px;
    height: 94px;
  }
}

@media (max-width: 1024px) {
  footer .images .img {
    width: 116px;
    height: 116px;
  }
}

@media (max-width: 768px) {
  footer .first-footer {
    padding-bottom: 0px;
  }
}
</style>