<template>
  <!-- <the-loader v-if="isLoading"></the-loader> -->
  <main >
    <the-scroll-buttons></the-scroll-buttons>
    <the-navbar></the-navbar>
    <router-view v-slot="slotProps">
      <transition name="fade" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
    <the-footer></the-footer>
  </main>
</template>
<script>
import { onMounted } from "vue";
import AOS from "aos";
// Import
import TheNavbar from "./components/layout/header/TheNavbar.vue";
import TheLoader from "./components/layout/TheLoader.vue";
import TheFooter from "./components/layout/TheFooter.vue";
import TheScrollButtons from "@/components/layout/TheScrollButtons.vue";

export default {
  components: {
    TheNavbar,
    TheLoader,
    TheFooter,
    TheScrollButtons,
  },
  // data() {
  //   return {
  //     isLoading: true,
  //   };
  // },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },

  setup() {
    onMounted(() => {
      AOS.init({
        offset: 0,
        duration: 800,
        once: true,
      });
    });
  },
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
