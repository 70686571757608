<template>
  <section class="carousel">
    <swiper dir="rtl" class="mySwiper" @swiper="onSwiper" @slideChange="onSlideChange" :options="swiperOption"
      :spaceBetween="100" :centeredSlides="true" :loop="true" :navigation="false" :modules="modules" :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }" :pagination="{
        clickable: true,
      }">
      <swiper-slide v-for="(slide, index) in sliders" :key="index">
        <div class="h-100 w-100" :style="{ background: slide.backgroundColor }" :class="slide.class">
          <div class="center-img position-absolute">
            <img :srcset="slide.deviceImg" :alt="slide.name" class="img-fluid" ref="deviceImg" />
          </div>
          <div class="like-img position-absolute" ref="likeImg">
            <img :srcset="slide.likeImg" :alt="slide.name" class="img-fluid" />
          </div>
          <div class="top-img position-absolute" ref="titleImg">
            <img :srcset="slide.titleImg" :alt="slide.name" class="img-fluid" />
          </div>
          <div class="top-text position-absolute" ref="text">
            <h1 class="img-fluid">{{ slide.text }}</h1>
          </div>
          <div class="number-img position-absolute" ref="text">
            <img :srcset="slide.numberImg" :alt="slide.name" class="img-fluid" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <!-- Start Navigation -->
    <!-- ### Prev Button ### -->
    <button class="button-prev d-none d-lg-block" @mouseenter="movePrevEnter" @mouseleave="movePrevLeave"
      @click="slidePrev">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <font-awesome-icon class="text-white" icon="fa-solid fa-angle-right" />
      </div>
    </button>
    <div class="pagination-image right d-none d-lg-flex align-items-center justify-content-center">
      <img :srcset="imgRight" alt="right" class="img-right w-100 h-100" :class="imgPrevAnimate" />
    </div>
    <!-- ### Next Button #### -->
    <button class="button-next d-none d-lg-block" @mouseenter="moveNextEnter" @mouseleave="moveNextLeave"
      @click="slideNext">
      <div class="w-100 h-100 d-flex align-items-center justify-content-center">
        <font-awesome-icon class="text-white" icon="fa-solid fa-angle-left" />
      </div>
    </button>
    <div class="pagination-image left d-none d-lg-flex align-items-center justify-content-center">
      <img :srcset="imgLeft" alt="left" class="img-left w-100 h-100" :class="imgNextAnimate" />
    </div>
    <!-- End Navigation -->

    <!-- Start Arrow -->
    <a href="#contactUs" class="go-down d-none d-xl-block">
      <font-awesome-icon icon="fa-solid fa-angles-down" />
    </a>
    <!-- End Arrow -->
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/css/landing-carousel.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import gsap from "gsap";
import { onMounted, ref } from "vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        onSlideChangeEnd: function () {
          this.onSwipe();
        },
      },
      next: false,
      prev: false,
      swiper: null,
      carouselIndex: 0,
      sliders: [
        {
          index: 0,
          class: "carouselOne",
          backgroundColor: "var(--primary-color)",
          numberImg: "/images/carousel/one/number.png",
          titleImg: "/images/carousel/one/title.png",
          likeImg: "/images/carousel/one/like.png",
          deviceImg: "/images/carousel/one/device.png",
          text: "أفضل مكان لتطوير أي تطبيق",
        },
        {
          index: 1,
          class: "carouselTwo",
          backgroundColor: "#015e5f",
          numberImg: "/images/carousel/two/number.png",
          titleImg: "/images/carousel/two/title.png",
          likeImg: "/images/carousel/two/like.png",
          deviceImg: "/images/carousel/two/device.png",
          text: "اصنع موقع الويب المثالي لك",
        },
        {
          index: 2,
          class: "carouselThree",
          backgroundColor: "#7a2d45",
          numberImg: "/images/carousel/three/number.png",
          titleImg: "/images/carousel/three/title.png",
          likeImg: "/images/carousel/three/like.png",
          deviceImg: "/images/carousel/three/device.png",
          text: " السيطرة على سوق التجارة الإلكترونية",
        },
      ],
    };
  },
  setup() {
    const titleImg = ref();
    const text = ref();
    const deviceImg = ref();
    const likeImg = ref();
    onMounted(() => {
      gsap.fromTo(
        titleImg.value,
        { translateX: "-100px" },
        { translateX: "0", duration: 1 }
      );
      gsap.fromTo(
        text.value,
        { translateX: "100px" },
        { translateX: "0", duration: 1, delay: 0.2 }
      );
      gsap.fromTo(
        deviceImg.value,
        { scale: "0.5" },
        { scale: "1", duration: 1, delay: 0.2 }
      );
      gsap.fromTo(
        likeImg.value,
        { rotation: "180" },
        { rotation: "0", duration: 1, delay: 0.2 }
      );
    });
    const onSlideChange = () => {
      gsap.fromTo(
        titleImg.value,
        { translateX: "-100px" },
        { translateX: "0", duration: 1 }
      );
      gsap.fromTo(
        text.value,
        { translateX: "100px" },
        { translateX: "0", duration: 1, delay: 0.2 }
      );
      gsap.fromTo(
        deviceImg.value,
        { scale: "0.5" },
        { scale: "1", duration: 1, delay: 0.2 }
      );
      gsap.fromTo(
        likeImg.value,
        { rotation: "180" },
        { rotation: "0", duration: 1, delay: 0.2 }
      );
    };
    return {
      modules: [Autoplay, Pagination, Navigation],
      onSlideChange,
      titleImg,
      text,
      deviceImg,
      likeImg,
    };
  },
  computed: {
    imgPrevAnimate() {
      return {
        "img-right-trans": this.prev,
      };
    },
    imgNextAnimate() {
      return {
        "img-left-trans": this.next,
      };
    },
    imgRight() {
      switch (this.carouselIndex) {
        default:
          return "/images/carousel/carousel3.png";
        case 0:
          return "/images/carousel/carousel3.png";
        case 1:
          return "/images/carousel/carousel1.png";
        case 2:
          return "/images/carousel/carousel2.png";
      }
    },
    imgLeft() {
      switch (this.carouselIndex) {
        default:
          return "/images/carousel/carousel2.png";
        case 0:
          return "/images/carousel/carousel2.png";
        case 1:
          return "/images/carousel/carousel3.png";
        case 2:
          return "/images/carousel/carousel1.png";
      }
    },
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    slideNext() {
      this.swiper.slideNext();
    },
    slidePrev() {
      this.swiper.slidePrev();
    },
    movePrevEnter() {
      this.prev = !this.prev;
    },
    movePrevLeave() {
      this.prev = !this.prev;
    },
    moveNextEnter() {
      this.next = !this.next;
    },
    moveNextLeave() {
      this.next = !this.next;
    },
    onSwipe(variable) {
      this.carouselIndex = variable.swiper.realIndex;
    },
  },
  mounted() {
    this.swiper.on("slideChange", () => {
      this.onSwipe(this);
    });
  },
};
</script>

<style scoped>
.carousel {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.swiper {
  width: 100%;
  height: 100%;
}

/* Start Navigation */
.button-next,
.button-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgb(17, 17, 17);
  height: 80px;
  width: 50px;
  overflow: hidden;
  z-index: 20;
}

.button-next {
  left: 0;
}

.button-prev {
  right: 0;
}

.pagination-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 80px;
  width: 100px;
  z-index: 19;
}

.pagination-image.right {
  right: 0;
}

.pagination-image.left {
  left: 0;
}

.pagination-image img {
  transition-duration: 0.5s;
}

.img-right {
  transform: translateX(100px);
}

.img-right-trans {
  transform: translateX(-50px);
}

.img-left {
  transform: translateX(-100px);
}

.img-left-trans {
  transform: translateX(50px);
}

/* End Navigation */
/* Start Go Down arrow */
.go-down {
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  transition: 0.5s ease;
}

.go-down:hover {
  color: #141414;
}

.go-down svg {
  font-size: 21px;
  -webkit-animation: bouncing 1.5s infinite;
  animation: bouncing 1.5s infinite;
}

.go-down i:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

@keyframes bouncing {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40%,
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

/* End Go Down arrow */
</style>
