<template>
    <section class="error-page">
        <div class="container w-100 h-100">
            <div class="d-flex align-items-center justify-content-center  w-100 h-100">
                <div class=" d-flex flex-column align-items-center gap-3 text-black">
                    <img src="/images/404.png" class="img-fluid" alt="404 page">
                    <h5 class="mb-0">عفواً</h5>
                    <p class="fs-6 mb-0">حدث خطا ما !</p>
                    <p class="fw-bold fs-5 mb-0">يرجى الاتصال بفريق الدعم لدينا</p>
                    <router-link to="/home" class="btn btn-secondary px-5 py-2 my-3">الصفحة الرئيسية</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.error-page {
    width: 100%;
    height: 100vh;
}
</style>