<template>
  <section class="pb-5">
    <base-banner mode="grey">اعمالنا</base-banner>
    <div class="container">
      <div class="box">
        <div class="row">
          <div class="col-xl-9">
            <div class="text">
              <h3 data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                {{ selectedPortfolio.name }}
              </h3>
              <span class="text-dark fw-bold d-block" v-if="selectedPortfolio.trade">علامة تجارية (مسجلة)</span>
              <p data-aos="fade-up" data-aos-easing="ease" data-aos-delay="600">
                {{ selectedPortfolio.details }}
              </p>
              <p v-if="specifications" class="fs-6 text-dark" data-aos="fade-up" data-aos-easing="ease"
                data-aos-delay="700">
                يوفر التطبيق الأقسام:
              </p>
              <ul v-if="specifications">
                <li v-for="(spec, index) in specifications" :key="spec.id" data-aos="fade-up" data-aos-easing="ease"
                  :data-aos-delay="700 + index * 50">
                  {{ spec }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="details">
              <table>
                <tr data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                  <th>الفئة:</th>
                  <td>{{ selectedPortfolio.type }}</td>
                </tr>
                <tr data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                  <th>التاريخ:</th>
                  <td>{{ selectedPortfolio.date }}</td>
                </tr>
                <tr data-aos="fade-up" data-aos-easing="ease" data-aos-delay="800">
                  <th>تابعنا:</th>
                  <td>
                    <ul class="social-links d-flex align-items-center gap-3 px-0 mb-0">
                      <li v-for="social in socialMedia" :key="social.id">
                        <a :href="social.link">
                          <font-awesome-icon :icon="social.icon" />
                        </a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row gap-5 my-4">
        <div class="col-12" v-for="image in images" :key="image.id">
          <img class="img-fluid d-block mx-auto" :srcset="image" :alt="selectedPortfolio.name" />
        </div>
      </div>

      <div class="pagination d-flex align-items-center justify-content-between mt-5">
        <div class="prev-btn">
          <button @click="goPrev" :disabled="preventBack">
            <font-awesome-icon icon="fa-solid fa-chevron-right" />
          </button>
        </div>
        <div>
          <router-link to="/portfolios">
            <font-awesome-icon icon="fa-solid fa-bars-staggered" />
          </router-link>
        </div>
        <div class="next-btn">
          <button @click="goNext" :disabled="preventNext">
            <font-awesome-icon icon="fa-solid fa-chevron-left" />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["id"],
  data() {
    return {
      selectedPortfolio: {},
      disableNext: false,
      disablePrev: false,
      portfolios: [],
      portfoliosCount: 0,
      index: null,
    };
  },
  created() {
    // Get Selected Portfolio ID
    this.selectedPortfolio = this.$store.getters.getPortfolio(this.id);
    // Get The Current Index
    this.portfolios = this.$store.getters["portfolios"];
    this.portfoliosCount = this.portfolios.length;
    this.index = this.portfolios.indexOf(this.selectedPortfolio);
  },
  computed: {
    images() {
      return this.selectedPortfolio.images;
    },
    specifications() {
      return this.selectedPortfolio.specifications;
    },
    socialMedia() {
      return this.$store.getters["social"];
    },
    preventBack() {
      if (this.index === 0) {
        return true;
      } else return false;
    },
    preventNext() {
      if (this.index === this.portfoliosCount - 1) {
        return true;
      } else return false;
    },
  },
  methods: {
    goPrev() {
      if (this.index !== 0) {
        this.index--;
        this.selectedPortfolio = this.portfolios[this.index];
        this.$router.replace("/portfolio/" + this.portfolios[this.index].id);
      }
    },
    goNext() {
      if (this.index !== this.portfoliosCount - 1) {
        this.index++;
        this.selectedPortfolio = this.portfolios[this.index];
        this.$router.replace("/portfolio/" + this.portfolios[this.index].id);
      }
    },
  },
};
</script>
<style scoped>
.single-portfolio {
  padding-top: 100px;
  padding-bottom: 60px;
}

.box {
  margin-bottom: 50px;
}

.box .text h3 {
  font-size: 30px;
  margin-bottom: 22px;
  color: var(--header-color);
  font-weight: 800;
}

.box .text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
  color: grey;
  text-align: justify;
}

.box .text ul {
  list-style: disc !important;
}

.box .text ul li {
  margin-bottom: 5px;
  color: grey;
}

.box .details th {
  padding: 10px;
  font-size: 19px;
  line-height: 1.23em;
  font-weight: 700;
}

.box .details td {
  padding: 10px;
  color: #8c8c8c;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.social-links a {
  color: #8c8c8c;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

.social-links a:hover {
  color: var(--primary-color);
}

.pagination button {
  background-color: transparent;
  border: 0;
  transition: 0.3s ease;
}

.pagination button:disabled {
  cursor: no-drop;
}

.pagination button:disabled svg {
  color: #ddd;
}

.pagination svg {
  font-size: 34px;
  line-height: 40px;
  color: var(--header-color);
  transition: 0.5s ease;
}

@media (min-width: 992px) {
  .pagination .prev-btn svg:hover {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  .pagination .next-btn svg:hover {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }

  .pagination svg:hover {
    color: var(--primary-color);
  }
}

@media (max-width: 1024px) {
  .box .details {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .pagination svg {
    font-size: 21px;
  }
}

/* End single-portfolio */
</style>
