<template>
    <div class="share-link" data-aos="fade-up">
        <span class="share-link-handler" :class="{ active: isActive }" @click="toggleShareBtn" data-title="مشاركة">
            <font-awesome-icon icon="fa-solid fa-share-nodes" />
        </span>

        <span class="share-link-item" data-title="لينكد ان">
            <a :href="'https://www.linkedin.com/sharing/share-offsite/?url=' + currentUrl" target="_blank">
                <font-awesome-icon icon="fa-brands fa-linkedin-in" />
            </a>
        </span>

        <span class="share-link-item" data-title="انستقرام">
            <a :href="'https://www.instagram.com/?url=' + currentUrl" target="_blank">
                <font-awesome-icon icon="fa-brands fa-instagram" />
            </a>
        </span>

        <span class="share-link-item" data-title="فايسبوك">
            <a :href="'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl" target="_blank">
                <font-awesome-icon icon="fa-brands fa-facebook-f" />
            </a>
        </span>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isActive: false,
            currentUrl: "",
        };

    },
    created() {
        this.currentUrl = window.location.href;
    },
    methods: {
        toggleShareBtn() {
            this.isActive = !this.isActive
        }
    }
}
</script>

<style scoped>
.share-link>span {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0;
    border-radius: 50%;
    font-size: 20px;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.share-link>span::before {
    content: attr(data-title);
    display: inline-block;
    width: auto;
    height: 30px;
    line-height: 30px;
    font-size: 10px;
    padding: 0 14px;
    border-radius: 5px;
    background-color: white;
    color: #212121;
    position: absolute;
    bottom: calc(100% + 10px);
    right: -30px;
    visibility: hidden;
    opacity: 0;
    white-space: nowrap;
}

.share-link>span:hover::before {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    z-index: 99;
}

.share-link>.share-link-handler {
    border: 2px solid transparent;
    background-color: white;
    color: #292f33;
    z-index: 3;
    -webkit-transition: background-color 0.3s ease-in, color 0.2s ease-in;
    transition: background-color 0.3s ease-in, color 0.2s ease-in;
    -webkit-animation: pulse 2s infinite;
    animation: pulse 2s infinite;
    cursor: pointer;
}

.share-link>.share-link-handler:hover {
    background-color: var(--primary-color);
    color: white;
    border-color: white;
    -webkit-transition: background-color 0.3s ease-in, color 0.2s ease-in;
    transition: background-color 0.3s ease-in, color 0.2s ease-in;
}

.share-link>.share-link-handler.active {
    background-color: var(--primary-color);
    color: white;
    border-color: white;
    -webkit-transition: background-color 0.3s ease-in, color 0.2s ease-in;
    transition: background-color 0.3s ease-in, color 0.2s ease-in;
}

.share-link>.share-link-handler.active:hover::before {
    opacity: 0;
    visibility: hidden;
}

.share-link>.share-link-handler.active~.share-link-item {
    -webkit-transition: -webkit-transform 0.3s ease-in;
    transition: -webkit-transform 0.3s ease-in;
    transition: transform 0.3s ease-in;
    transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}

.share-link>.share-link-handler.active~.share-link-item:nth-child(2) {
    -webkit-transform: translate(-75%, -250%);
    transform: translate(-75%, -250%);
    -webkit-transition-delay: 0;
    transition-delay: 0;
}

.share-link>.share-link-handler.active~.share-link-item:nth-child(3) {
    -webkit-transform: translate(100%, -190%);
    transform: translate(100%, -190%);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.share-link>.share-link-handler.active~.share-link-item:nth-child(4) {
    -webkit-transform: translate(150%, -25%);
    transform: translate(150%, -25%);
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

.share-link>.share-link-item {
    height: 30px;
    width: 30px;
    line-height: 30px;
    font-size: 17px;
    cursor: pointer;
    z-index: 2;
    -webkit-box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.25);
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
}

.share-link>.share-link-item a {
    color: #8c8c8c;
}

.share-link>.share-link-item a:hover {
    color: var(--primary-color);
}

/* pules effect */
@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 196, 0, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(250, 196, 0, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 196, 0, 0);
    }
}

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 196, 0, 0.4);
        box-shadow: 0 0 0 0 rgba(250, 196, 0, 0.4);
        -moz-box-shadow: 0 0 0 0 rgba(250, 196, 0, 0.4);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(250, 196, 0, 0);
        box-shadow: 0 0 0 20px rgba(250, 196, 0, 0);
        -moz-box-shadow: 0 0 0 20px rgba(250, 196, 0, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(250, 196, 0, 0);
        box-shadow: 0 0 0 0 rgba(250, 196, 0, 0);
        -moz-box-shadow: 0 0 0 0 rgba(250, 196, 0, 0);
    }
}

@media (max-width:540px) {
    .share-link>.share-link-handler.active~.share-link-item:nth-child(2) {
        transform: translate(150%, -50%);
    }

    .share-link>.share-link-handler.active~.share-link-item:nth-child(3) {
        transform: translate(300%, -50%);

    }

    .share-link>.share-link-handler.active~.share-link-item:nth-child(4) {
        transform: translate(450%, -50%);

    }
}
</style>