<template>
  <section>
    <div class="our-strategy">
      <div class="container">
        <!-- Start Section Title -->
        <base-title>استراتيجيتنا</base-title>
        <!-- End Section Title -->

        <div class="row">
          <!-- Strategy 1 -->
          <div class="col-xl-3 col-lg-6 col-sm-12 " v-for="(strategy , index) in strategies" :key="strategy" data-aos="fade-down"
            data-aos-easing="ease" :data-aos-delay="400 + index*50">
            <div class="box">
              <div class="icon position-relative">
                <div class="image">
                  <img :srcset="strategy.image" class="w-100 h-100">
                </div>
              </div>
              <div class="box-body text-center">
                <h4>{{strategy.title}}</h4>
                <p>{{strategy.description}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Conclusion -->
    <div class="conclusion d-flex flex-column justify-content-center align-items-center gap-3 w-100">
      <h2 class="text-white fw-bold" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400"> مجرد مكان مثالي
        للبدء</h2>
      <base-arrow button @click="showDialog">اتصل بنا</base-arrow>
    </div>
    <!-- End Conclusion -->
    <contact-modal @close="hideDialog" :open="dialogIsVisible"></contact-modal>
  </section>
</template>


<script>
  import ContactModal from '../contact/ContactModal.vue';

export default {
  components: {
        ContactModal
    },
  data() {
    return {
      strategies: [
        {
          id: '1',
          image: '/images/strategy/quality1.png',
          title: 'التخطيط أولا',
          description: 'سنتواصل معك من أجل إيجاد خطة ترضي احتياجاتك'
        },
        {
          id: '2',
          image: '/images/strategy/quality2.png',
          title: 'التصميم هو الثاني',
          description: 'سيبدأ فريقنا في عملية التصميم بناءً على ما تم الاتفاق عليه في جزء التخطيط'
        },
        {
          id: '3',
          image: '/images/strategy/quality3.png',
          title: 'ثم هناك تطوير',
          description: 'سيبدأ فريق المطورين العبقريين لدينا في تطوير كل ما تطلبه بطريقة ستذهلك'
        },
        {
          id: '4',
          image: '/images/strategy/quality4.png',
          title: 'أخيرًا ، الاختبار',
          description: 'سنختبر كل ما تم تطويره مرارًا وتكرارًا حتى نتأكد تمامًا من اختفاء كل الأخطاء'
        },
      ],
      dialogIsVisible: false
    }
  },
  methods: {
        showDialog(){
            this.dialogIsVisible = true
        },
        hideDialog(){
            this.dialogIsVisible = false
        }
        
    },
}
</script>


<style scoped>
section{
  padding-top: 60px;
}
.our-strategy{
  margin-bottom: 100px;
}
.our-strategy .box {
  width: 280px;
  height: 255px;
  margin: 0 auto 30px;
  position: relative;
}

.our-strategy .box:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  top: 0;
  right: 0%;
}

.our-strategy .box::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  bottom: 0;
  left: 0%;
}

.our-strategy .box:hover::before {
  height: 100%;
  background-color: var(--primary-color);
}

.our-strategy .box:hover::after {
  height: 100%;
  background-color: var(--primary-color);
}

.our-strategy .box .icon {
  height: 85px;
  width: 100%;
}

.our-strategy .box .icon .image {
  width: 70px;
  height: 75px;
  margin: 0 auto 8px;
  z-index: 2;
}

.our-strategy .box .icon::before {
  content: "0" counter(icon-counter);
  counter-increment: icon-counter;
  position: absolute;
  left: 50%;
  top: 50%;
  color: #f6f6f6;
  font-size: 130px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  overflow: hidden;
  font-family: Merriweather, serif;
}

.our-strategy .box .box-body {
  padding-top: 23px;
  width: 260px;
}

.our-strategy .box .box-body h4 {
  color: var(--header-color);
  font-weight: 700;
  font-size: 19px;
  line-height: 1.2em;
}

.our-strategy .box .box-body p {
  color: grey;
  font-size: 15px;
  margin-top: 13px;
}

@media (min-width: 480px) and (max-width: 1024px) {
  .our-strategy .box {
    width: 300px;
    height: 255px;
  }

  .our-strategy .box .box-body {
    width: 280px;
  }
}

@media (max-width: 300px) {
  .our-strategy .box {
    width: 260px;
  }

  .our-strategy .box .box-body {
    width: 240px;
  }
}

.conclusion {
  background-image: url(../../../public/images/banners/yellow.jpg);
  background-size: cover;
  height: 250px;
}
</style>