
import blogsGetters from './getters.js';

export default {
    state() {
        return {
            blogs: [
                {
                    id: '1',
                    title: 'التجارة الإلكترونية',
                    question: 'ما هو مستقبل التجارة الإلكترونية؟',
                    imageSM: '/images/blogs/blog1.png',
                    imageLG: '/images/blogs/blog1-lg.jpg',
                    dates: [
                        {
                            month: 'ك 2',
                            day: '12'
                        }
                    ],
                },
                {
                    id: '2',
                    title: 'التواجد الإلكتروني',
                    question: 'لماذا التواجد على الإنترنت مهم جدًا وكيف يساعدك؟',
                    imageSM: '/images/blogs/blog2.png',
                    imageLG: '/images/blogs/blog2-lg.jpg',
                    dates: [
                        {
                            month: 'ت 1',
                            day: '24'
                        }
                    ],
                },
                {
                    id: '3',
                    title: 'تطوير تطبيقات الويب',
                    question: 'ما هو تطوير تطبيقات الويب الحديثة؟',
                    imageSM: '/images/blogs/blog3.png',
                    imageLG: '/images/blogs/blog3-lg.jpg',
                    dates: [
                        {
                            month: 'آب',
                            day: '03'
                        }
                    ],
                },
                {
                    id: '4',
                    title: 'الاستضافة',
                    question: 'ما أنواع الاستضافة؟',
                    imageSM: '/images/blogs/blog4.png',
                    imageLG: '/images/blogs/blog4-lg.jpg',
                    dates: [
                        {
                            month: 'ك 1',
                            day: '22'
                        }
                    ]
                },
                {
                    id: '5',
                    title: 'الواقع',
                    question: 'ما الفرق بين الواقع المعزز والواقع الافتراضي؟',
                    imageSM: '/images/blogs/blog5.png',
                    imageLG: '/images/blogs/blog5-lg.jpg',
                    dates: [
                        {
                            month: 'ايلول',
                            day: '7'
                        }
                    ]
                },

            ]
        }
    },
    getters: blogsGetters
}